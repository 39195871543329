import React from 'react';



const PrivacyPolicyPage = () => {


    return (


            <main>
                <div class="_container_4214v_15" bis_skin_checked="1"><div class="_page_fni8s_1" bis_skin_checked="1">
                    <div class="_content_fni8s_9" bis_skin_checked="1">
                        <div bis_skin_checked="1"><h1>Privacy Policy</h1><p>This Privacy Policy ('Policy') describes how the Personal Data of Data Subjects is processed by EXPI ('EXPI' or 'We') when they use the Website ('Website') and/or interact with our online advertisements or marketing emails.</p></div>
                        <div bis_skin_checked="1">
                            <p>Please read this Policy carefully. By accepting this Policy, you agree to comply with it and all terms included by reference. If you disagree with this Policy or any of its provisions, you must immediately cease using our Website.</p></div>
                        <div bis_skin_checked="1">
                            <p>EXPI responsibly approaches the collection and use of data from its Data Subjects. The Policy was designed in such a way that we confirm that our internal processes comply with the Policy.</p></div>
                        <div bis_skin_checked="1">
                            <p>Please note that according to the Terms of Use, EXPI acts as an aggregator, service provider, and intermediary between Users and various virtual currency exchange services worldwide, which act as external third parties in relation to EXPI.</p></div>
                        <div bis_skin_checked="1">
                            <p>The services of the Website are limited to providing technical support for the user interface to facilitate the transaction; EXPI is not a party to virtual currency exchange transactions and has no authority to make decisions regarding rates or commissions. EXPI is not a party to exchange transactions and has no authority to make decisions regarding rates or commissions. </p></div>
                        <div bis_skin_checked="1">
                            <h2>DEFINITIONS:</h2>
                            <p>'Website' - the website with the URL address:  https://expi.bot.</p>
                            <p>'EXPI', 'Ourselves', 'We' and 'Us', refers to the EXPI company.</p>
                            <p>'Personal Data' - information that relates to the Data Subject and identifies the Data Subject.</p></div>
                    <div bis_skin_checked="1">
                        <h2>PURPOSES OF DATA PROCESSING</h2><p>EXPI has the right to process Personal Data for the following purposes: KYB procedure, analysis of Data Subjects' interaction with the Site, statistical and analytical purposes, User support, improvement of the Site's operation; providing the User with all the functional capabilities of the Site. </p></div>
                        <div bis_skin_checked="1">
                            <h2>LEGAL BASIS FOR DATA PROCESSING AND LIST OF PROCESSED DATA</h2><p>There are legal grounds for processing Personal Data, and we rely on them when processing your Personal Data.</p><p>We do not collect any Personal Data other than the Personal Data specified in this Policy. Other data may be collected by third parties; we are not responsible for the actions of third parties.</p></div>
                        <div bis_skin_checked="1">
                            <p>To process your Personal Data, we use four main grounds: consent, legal obligation, legitimate interests (to fulfill the terms):</p><ul><li>Name and email address</li>
                            <li>Support service</li></ul><h2>KYB PROCEDURE</h2><p>In accordance with the terms of the AML policy of the exchange service, EXPI has the right to process Personal Data of UBO for the purpose of verifying compliance with applicable anti-money laundering regulations and KYB rules. The list of data processed during the KYB procedure is specified in the text of the Policy.</p></div>
                        <div bis_skin_checked="1">
                            <h2>COOKIE</h2><p>Cookies are small pieces of data sent by our web server and stored on the user's device. Cookies are saved when you visit a website, and they allow us to ensure its efficient operation: cookies enable us to save your chosen settings (e.g., language) and analyze website traffic.</p></div>
                        <div bis_skin_checked="1">
                            <p>EXPI uses the following cookies:</p><p>Essential cookies: help make the website user-friendly by enabling basic functions such as page navigation and access to secure areas of the website. Without these cookies, the website cannot function properly.</p><p>Statistics cookies: help website owners understand how visitors interact with websites by collecting and transmitting information anonymously. These cookies may record data about visitors' website behavior, collect statistics on user interaction with the price and inventory panel on the website in real-time.</p></div>
                        <div bis_skin_checked="1">
                            <p>Marketing: used to track website visitors. The aim is to display ads that are relevant and interesting to the individual user and therefore more valuable to publishers and third-party advertisers.</p><p>ЭThese cookies can:</p></div>
                        <div bis_skin_checked="1">
                            <ul><li>determine whether any ad-blocking software is installed in the visitor's browser—this information can be used to make the website's content inaccessible to visitors if the website is funded by third-party advertising,</li><li>save information about actions performed by the user during the current site visit, including search queries with keywords,</li><li>establish a unique identifier for the visitor, which allows third-party advertisers to target the visitor with relevant advertising.</li></ul><h2>HIRD PARTIES AND LIMITATION OF LIABILITY</h2><p>Please note that EXPI does not process end-user payment data. As stated in our Terms of Use, the Website is an aggregator, service provider, and intermediary between the end user and various virtual currency exchange services that act as external third parties with respect to EXPI.</p></div>
                        <div bis_skin_checked="1">
                            <p>Virtual currency transactions are conducted exclusively between end users and third parties. For this reason, EXPI does not process any personal data processing resulting from these transactions. This means that the collection and processing of all information about exchange services, transactions, and data required for the KYC procedure are handled by third parties and governed by the provisions of their privacy policies.</p></div>
                        <div bis_skin_checked="1">
                        <p>In no case can EXPI be considered the data controller in this regard. EXPI does not have access to information about these transactions, nor does it store it. EXPI receives payment data through API requests from the exchange service and transmits this data to the client.</p></div>
                    <div bis_skin_checked="1">
                        <p>The website may contain links to third-party websites. This Policy does not apply to the privacy policies of such third parties. These third parties have their own privacy policies, and we bear no responsibility for their websites, features, or policies. Please familiarize yourself with these providers and their privacy and responsibility policies.</p></div>
                        <div bis_skin_checked="1">
                            <h2>DATA SUBJECTS' RIGHTS</h2><p>Each data subject has the right to:</p><ul><li>access information about the Personal Data processed by EXPI.</li><li>correct inaccurate data about the Data Subject.</li><li>delete Personal Data about the Data Subject.</li><li>receive Personal Data about the Data Subject and transfer such data to another controller.&lt;</li><li>object to the processing of Personal Data on grounds related to a specific situation.</li><li>evoke the Data Subject's consent to the processing of Personal Data.</li><li>If the data subject intends to exercise any of the provided rights, the data subject should send a letter or email to the EXPI company using the EXPI contact details provided in this Policy.</li></ul><p>As soon as we receive any of your requests, we will review it and make a decision within 1 month, unless there is a justified demand to provide such information sooner. This period may be extended in accordance with applicable law. We may request specific information from you to confirm your identity when necessary and reasonable.</p></div>
                        <div bis_skin_checked="1">
                            <h2>HOW DO WE PROTECT YOUR DATA?</h2><p>EXPI takes all reasonable measures to protect the Personal Data of the Data Subject from unauthorized access by third parties, as well as from loss, misuse, alteration, or destruction of Personal Data, but we cannot guarantee that these measures will stop any users trying to get around the privacy or security settings on the Website as a result of unforeseen and/or illegal actions.</p></div>
                        <div bis_skin_checked="1">
                            <p>We adhere to generally accepted standards for protecting your information. In particular, we have adopted at least the following measures:</p><ul><li>Appointment of a person responsible for organizing the processing of personal data</li><li>Identification of the list of employees who have access to Personal Data;</li><li>Encryption of data during storage and transmission;</li><li>Organization of the procedure for the destruction of personal data after the expiration of their processing period;</li><li>Conducting internal audits to comply with the requirements for ensuring the security of personal data;</li><li>Defining access rules to personal data processed in personal data information systems;</li><li>Defining the procedure for responding to information security incidents.</li></ul><h2>STORAGE OF PERSONAL DATA</h2><p>&gt;We strive to limit the processing period of Personal Data to the necessary minimum and not to store them longer than is reasonably necessary. Usually, this period is limited to 1 year, but if the current data protection legislation prescribes the storage of personal data for a longer or shorter period of time, we will store personal data for a period in accordance with these legislative requirements.</p></div>
                        <div bis_skin_checked="1">
                            <h2>AMENDMENTS</h2><p>The available Policy is presented in the most current version. Over time, we may unilaterally make changes to the Policy, including to comply with the latest changes in current legislation and court practice. All changes come into effect from the moment they are published on this page unless the text of the changes specifies a different term. EXPI will make every effort to organize additional ways of notifying users about changes in the Policy, but we ask you to regularly check for the current version yourself.</p><h2>CONTACT</h2><p>f you have any questions regarding this Privacy Policy, your rights and obligations, and/or your use of the Website or any other questions, please contact us at support.</p></div>
                    </div></div></div></main>

    );
};

export default PrivacyPolicyPage;
