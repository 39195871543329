import React from 'react';
import { Header,HomePageBlockHero,HomePageBlockPrivateKey,HomePageBlockLastTransaction,HomePageBlockAdvantages,Footer } from "./";



const HomePage = () => {


    return (

        <div>

        <HomePageBlockHero/>
        <HomePageBlockPrivateKey/>
        <HomePageBlockLastTransaction/>
        <HomePageBlockAdvantages/>


        </div>

    );
};

export default HomePage;
