import React,{ useState } from 'react';
import {Header, Footer} from "./";
import arbitratimg from '../img/arbitrat-strategy.svg';

const faqData = [
    {
        id: 1,
        question: "What is EXPI Bot?",
        answer: "EXPI Bot is a new solution for instant cryptocurrency exchange with a simplified arbitrage strategy. You register on EXPI and work directly with the exchange supplier using a transit crypto wallet.\n The principle of arbitrage exchanges on EXPI Bot is very simple: you analyze and compare the exchange rates of cryptocurrencies in variousavailable pairs, from different exchange providers and in different countries, fixthe rate of the exchange profitable for you, confirm the operation to StartExchange, send and receive the result with profit.\n\nEXPI Bot is not only a smart aggregator for cryptocurrency exchange but also a provider of exchange services. EXPI Bot provides users with all the necessary information for cryptocurrency exchange so that you can choose the best option. You can use the list of exchange services on EXPI Bot and independently choose to send the asset to conduct the exchange operation on the service with the most favorable price.\n EXPI Bot offers the crypto community a convenient, progressive, and transparent service. Subscribing to EXPI Bot makes the exchange process profitable and saves your time. Choosing the best offer for cryptocurrency exchange is never an easy task, but EXPI Bot can help not only solve this task but also calculate the best arbitrage exchange delta with just one click of a mouse."
    },
    {
        id: 2,
        question: "How to register on EXPI Bot?",
        answer: "You register on EXPI Bot, receive the Private Key, and work with the EXPI transit payment wallet – you give to the provider and receive from the provider exchangeable assets according to the generally accepted principle of the cryptocurrency exchange service. We do not collect any data other than your Email address when receiving the access key to EXPI Bot services."
    },
    {
        id: 3,
        question: "What crypto pairs does EXPI Bot support?",
        answer: "EXPI Bot supports all cryptocurrency pairs that have a profitable exchange delta. You may think that the choice of assets is not that extensive, but this is not the case. You see the best offer that the market can offer you today. The list of assets is constantly updated, and as soon as the EXPI Bot monitoring sees a profitable batch in a certain pair, it adds it to the list for your choice and the choice of bots in the automatic exchange mode.\n The base asset for EXPI Bot is the stablecoin USDT (Polygon), which you send to the transit wallet for exchanges in different pairs and receive stable  profit from exchanges in USDT (Polygon)."
    },
    {
        id: 4,
        question: "Why should I trust EXPI?",
        answer: "The EXPI service provides a service of direct crypto exchanges, where the result of the exchange is profit.\nAfter registering and gaining access to EXPI services, you get not only an aggregator of cryptocurrency exchange services, which will help you easily find the best offer, but also an automated arbitrage solution that works via API connection with fifty-six exchangers in different countries and performs direct exchange operations on these sites. It is worth noting that EXPI cooperates under a PaaS License Agreement with the licensed service SdbS Group, which works with the most reliable crypto exchanges. To make it easier for users to choose the best price offers of the exchangeable asset, all exchange services have ratings with the best offers\n hanks to a convenient interface and reliable partners, EXPI has significantly simplified the exchange of crypto assets and provided access to a new format of profit generation. You always control your money on transit wallets, and each exchange is conducted on the principle of sending the asset for exchange and receiving the exchangeable asset on the transit address.\n EXPI also has a strict privacy policy, and all confidential information is encrypted. EXPI is integrated only with the most reliable partners. Thanks to their 'Know Your Business' protocol for checking exchange partners, you can be sure that the exchange partners integrated with EXPI are reliable.\nYou can trust the ratings and reviews of EXPI, and consider all other factors before using EXPI's paid services. EXPI provides you with the estimated exchange time and exchange rates for each exchange partner. With all this and many other useful tools, EXPI makes the exchange of crypto assets a reliable operation with a guaranteed profitable delta."
    },
    {
        id: 5,
        question: "Does EXPI hold funds?",
        answer: "EXPI offers a service not related to storage, where users maintain full control over their crypto assets. EXPI has a complex multifunctional security system and a licensed level of exchange service provision. Moreover, EXPI does not require any personal information from users, and there are no hidden fees or commissions.\nThanks to EXPI, users always have access to their crypto assets, reliable access to the exchange system, and receive stable profits using the capabilities of a unique exchange mechanism. This is arguably one of the main reasons why crypto traders, investors, and enthusiasts around the world are ready to trust EXPI."
    },
    {
        id: 6,
        question: "What is the price for EXPI services?",
        answer: "Exchanges on EXPI Bot are carried out in a simplified automatic mode and a profitable exchange delta is consistently extracted. EXPI Bot and SdbS exchange services guarantee the fixation of cryptocurrency rates during the exchange operation and protection from all possible risks of price volatility, which eliminates loss of profitability of the transaction or loss of capital.\nTherefore, EXPI Bot has a certain fee for its exceptional exchange services. This is a paid and free EXPI subscription.\nThe operating conditions for subscriptions are the same:\n Access to all top crypto pairs for exchange, exchanges are carried out with various amounts from 50 USDT to 100,000 USDT Performing from 30 exchange operations per day, free deposit and withdrawal of funds, receiving a profitable exchange delta from 36% per month. and higher.\nPayment conditions for subscriptions are different:\n 1. Paid subscription - this is when the client pays an amount of 71 USDT and opens the subscription for 7 days, then systematically renews it by making payment for the renewal.\n2. A free subscription is when the client does not pay for the subscription, but a fixed fee of 21% is applied to each amount of profit received from the exchange operation.\nEach client can individually choose which type of payment to use it is more convenient for him to work for EXPI's services.\nSubscription is a way of earning money for EXPI. Our service is integrated into the exchange network of the SdbS provider through paid integration, therefore, in order to provide clients with this profitable exchange service, we also need to establish payment for it."
    },
    {
        id: 7,
        question: "What are your commissions?",
        answer: "EXPI Bot has no hidden commissions.\nEXPI profit is the payment of a paid subscription or a fixed fee for providing fast exchange services.\n When withdrawn online USDT Polygon – blockchain network commissions are not withheld, as they are the minimum and EXPI\ pays them through payment for services.\n When withdrawing USDT in other blockchain networks (TRC or ERC-20), a commission is withheld, which is automatically pulled from the blockchain network at the time of withdrawal.\n When conducting exchanges, there are no commissions - you see the ready price for assets, which is generated by the exchange service, the price is broadcast via  the API connection and includes commissions for the blockchain network and exchange , as well as volatility indices.\n When replenishing the EXPI transit wallet, you pay the blockchain network commission as the sender of the  coins."
    }
];


const FaqPage = () => {

    const [activeIndex, setActiveIndex] = useState(0);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (


            <main>
                <div className="_container_4214v_15" bis_skin_checked="1">
                    <div className="_page_1jvq2_1" bis_skin_checked="1"><h1>FAQ</h1>
                        <ul className="_list_1jvq2_15">
                            {faqData.map((item, index) => (
                                <li className={`_item_1jvq2_23 ${activeIndex === index ? '_active_1jvq2_51' : ''}`} key={item.id} >
                                    <h3 className={`_itemTitle_1jvq2_28 `} onClick={() => handleToggle(index)}>{item.question}</h3>
                                    <div className="_itemContent_1jvq2_55" bis_skin_checked="1">{item.answer}</div>
                                </li>

                            ))}



                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">How does EXPI Bot
                                work?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>The EXPI Bot team
                                    understands that choosing an exchange platform can be difficult for crypto
                                    enthusiasts, traders, and investors. Nevertheless, EXPI Bot has made the
                                    cryptocurrency exchange process simple for everyone; moreover, EXPI Bot has provided
                                    a tool to extract profit from each exchange operation.</p><p>All you need to do is
                                    choose the cryptocurrency you want to exchange and get offers from various exchange
                                    platforms - exchange providers in different countries. Choose the exchange offer to
                                    buy the asset in a pair with USDT, which best meets your expectations, and the offer
                                    to sell the asset to receive USDT on the transit wallet with a profitable exchange
                                    delta. After fixing the selling and buying offers, you will see the broadcast of the
                                    expected exchange delta and the volume of your profit for the current exchange
                                    operation.</p><p>EXPI Bot provides you with the exchange rate taking into account
                                    all commissions and rate fixation, the approximate time needed to complete the full
                                    exchange operation, ratings and reviews of exchange platforms, as well as tools for
                                    online exchange broadcasting and checking the status of each exchange. What makes it
                                    even easier for clients to find the best offers is that the exchange offers with the
                                    best exchange rate are marked at the top of the offer tables.</p><p>EXPI Bot not
                                    only simplifies the exchange processes but also gives the client the opportunity to
                                    earn on exchanges, multiply money, and save time.</p></div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">How does EXPI Bot
                                integrate exchange platforms?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>We collaborate under a
                                    licensed PaaS and SaaS agreement and Agreements on the use of the aggregate REST API
                                    SdbS Exchange and API SdbS Pay, with more than fifty-six exchange platforms in
                                    different countries. EXPI aggregates cryptocurrency exchange offers across many
                                    cryptocurrency pairs through the API of these reliable exchange services. SdbS
                                    Exchange is a licensed network of cryptocurrency exchange platforms that provide
                                    reliable services and support a wide range of crypto assets.&gt;</p><p>EXPI Bot
                                    offers services not related to asset storage, and our users do not need to
                                    relinquish control over their funds; they have access to them at all times. EXPI Bot
                                    also guarantees cooperation only with reliable and secure exchange platforms for
                                    fast exchange services and stable profit extraction.</p></div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">How does EXPI Bot
                                aggregate exchange rates?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>EXPI Bot receives
                                    exchange rates directly from exchange service platforms. EXPI Bot continuously
                                    monitors and compares cryptocurrency exchange rates for all available cryptocurrency
                                    pairs supported by EXPI Bot and exchange service providers.</p><p>As mentioned
                                    earlier, in EXPI Bot, the exchange provider offering the best exchange rate will be
                                    listed at the top. EXPI Bot also informs users about offers with a fixed exchange
                                    rate only. Fixed-rate exchanges are operations in which you receive the exact amount
                                    of cryptocurrency after the exchange is completed. Fixed-rate exchanges guarantee
                                    that you will receive the amount of cryptocurrency calculated by the service at the
                                    beginning of the exchange. In doing so, the service forms the exchange price taking
                                    into account the Crypto Volatility Index, thereby insuring itself against volatility
                                    risks.</p></div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">How does EXPI calculate
                                exchange time?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>EXPI Bot strives to
                                    provide fast arbitrage exchange, so it is extremely important to provide the
                                    customer with information about the approximate exchange time. Although the time
                                    depends on several criteria, such as confirmation time for a specific asset or
                                    exchange service (service provider), a formula can still be composed for it.</p>
                                    <p>Asset1xXconfirmations + swapXconAsset2 + Xcontransfer + swapXconAsset2+
                                        tservice/nservic</p><p>In most cases, the processing time of a full mandate for
                                        arbitrage exchange operations, considering the stage of fixing the exchange
                                        asset in the client's wallet, can take from 20 to 40 minutes.</p></div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">Why are cryptocurrency
                                prices different in different countries?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>This is one of the
                                    questions that most cryptocurrency enthusiasts always ask themselves. The cost of
                                    certain assets varies on exchange services, different exchanges, and in different
                                    regions. Each crypto exchange service provider, with which EXPI works, is
                                    simultaneously connected to several major cryptocurrency exchanges in its country to
                                    always have an exchange volume. Volumes are limited in most cases since all mined
                                    coins have online exchange quotes, which constitute a small part of the total number
                                    of mined coins.</p><p>Cryptocurrency prices on different exchanges are based on
                                    trading, and there is no established method of pricing. The cost is usually balanced
                                    between the price one person is willing to exchange and the price another person is
                                    willing to part with to get the same currency. The price deal occurs between two
                                    people during the exchange after the upper and lower limits have been defined. The
                                    price is determined after that.</p><p>At any point in time, there is a price
                                    difference for cryptocurrency between exchanges by a few cents or even more.
                                    However, in most cases, a significant difference is observed between trading
                                    exchanges in different regions (countries) and is based, in addition to demand and
                                    supply, on additional factors such as adoption and mass use, legal regulation,
                                    influence of mainstream media, investor sentiment, etc. This is a common series of
                                    trends and economic cycles that influence pricing in different environments and
                                    allow identifying countries with the highest cryptocurrency usage rating and
                                    accordingly higher price for profitable sales and countries with average or low
                                    cryptocurrency usage rating and lower price for purchases. Thus works the arbitrage
                                    strategy of fast automatic cryptocurrency exchanges between different regions.</p>
                                    <p>For each exchange service of SdbS Exchange and for each cryptocurrency pair, the
                                        exchanges with which the exchange service is currently working are listed in the
                                        'Markets' section, there are quite a lot of markets (from 10 interactions for
                                        each country), and collectively they have significant trading volumes necessary
                                        for fast exchanges. The exchange service forms its own profitable and safe
                                        exchange price based on the data received from the exchange and the current
                                        market situation. All exchange and network commissions, as well as expected
                                        volatility level coefficients, are considered in the exchange price.</p>
                                    <p>Therefore, EXPI Bot clients can safely conduct arbitrage exchanges, calmly
                                        choosing the price taking into account all commissions and fixing it, without
                                        fearing sharp jumps and volatility.</p></div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">How to conduct an
                                arbitrage exchange on EXPI Bot?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>After registration,
                                    activation of the free subscription, and receipt of the private key, clients can
                                    start exchanging cryptocurrency. </p><p>To activate the transit wallet, send an
                                    amount of at least the minimum (50 USDT) to it. Select the necessary crypto pair in
                                    the 'Available Pairs' tab. </p><p>Fix the necessary exchange service for selling and
                                    accordingly for buying the asset.</p><p>Enter the volume of the asset to be
                                    exchanged.</p><p>Assess the profitability of the deal and the expected profit, and
                                    you can start the exchange.</p><p>After pressing the 'Start Exchange' button, the
                                    system will send the asset to the wallet of the exchange service, and the Bot will
                                    conduct the specified exchange operation.</p><p>After sending funds from the transit
                                    account, all clients need to do is wait for their request to be processed by the
                                    exchange service providers. You can track the exchange operation in the mode of
                                    online exchange broadcasts, through the account of the exchange provider, by all the
                                    status IDs of the exchange path at the exchange providers. As soon as the provider
                                    sends the exchanged amount to your transit wallet, and the transaction is received,
                                    the arbitrage swap will be executed. </p></div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">Why do you have limits on
                                the volume of a single exchange operation?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>EXPI Bot receives all
                                    exchange data through API integration from the provider SdbS Exchange. </p><p>This
                                    includes the buying and selling price, available liquidity of exchange markets where
                                    a particular exchange service can operate quickly. </p><p>We also work with requests
                                    for volumes located on the wallets of exchange services and intended for instant
                                    exchanges. Under the PaaS License Agreement, we have a percentage of liquidity from
                                    the volume of each exchange service, so we need to set a limit on one exchange
                                    operation for its successful confirmation. </p><p>EXPI Bot can accept a volume of no
                                    more than 100,000 USDT in equivalent for one arbitrage exchange operation; this is
                                    limited within the framework of the automatic exchange operations contract and
                                    allows for quick and successful transactions within the liquidity activity and
                                    market conditions. </p></div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">Can I work with maximum
                                volumes for direct and automatic exchanges simultaneously?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>Yes, you can create a
                                    complex arbitrage portfolio and work with direct exchanges in manual mode and
                                    automatic exchanges in fully automated mode. At the same time, use the maximum
                                    volumes for exchanges.</p></div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">How many exchange
                                operations can be conducted per day?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>The number of exchange
                                    operations performed directly depends on the characteristics of each crypto asset
                                    network you work with. Additionally, the duration of the operation can be influenced
                                    by the regulations of the exchange service (exchange provider) where the exchange is
                                    conducted and the peculiarities of the markets in a particular country. EXPI bot
                                    works via API connection, with liquidity provision, which significantly speeds up
                                    any operations.</p><p>It is impossible to determine the exact number of operations
                                    in 24 hours. We can provide the minimum number of operations recorded in the work
                                    statistics: in the USDT/Bitcoin pair – from 22-24 exchange operations per day and
                                    higher, in other available pairs, at least 40 exchange operations and higher.</p>
                                </div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">What is the Private
                                Key?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>In the EXPI bot system,
                                    there is registration and enhanced security with access to EXPI functions via a
                                    Private Key. The Private Key is generated based on the principle of a special hash
                                    function designed for generating crypto wallets, making it impossible to decrypt or
                                    guess the data. The cryptographic hash function algorithm ensures complete security
                                    and integrity of the Private Key data. You need to store it in a secure place and
                                    not share it with third parties, using it only for access to the EXPI system.</p>
                                    <p>The Private Key is sent to your email after registration and activation of the
                                        EXPI bot subscription; you use it consistently. In case of loss of the Private
                                        Key, you need to restore it through the recovery form, using the active Email
                                        address.</p><p></p></div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">What are the payment
                                terms for working with EXPI bot?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>You have two payment
                                    wallets (transit) in EXPI bot, one for direct and automatic exchanges. You can work
                                    with them simultaneously or choose one of them to work within the Direct and
                                    Automatic Exchanges section. </p><p>To log in to the system, you have your reliable
                                    Password and Private Key access, generated based on the principle of a special hash
                                    function, developed for generating crypto wallets. The cryptographic hash function
                                    algorithm ensures complete security and integrity of the Private Key data. You need
                                    to keep the password and key data in a safe place and not share it with third
                                    parties, using it only for access to the EXPI system. The Private Key is sent to
                                    your email after registration and activation of the EXPI bot subscription.</p>
                                    <p></p><p>You can add your personal crypto wallets, which you use to work with the
                                        EXPI transit wallet in exchange operations, deposits, and withdrawals, to the
                                        status of trusted addresses in the EXPI system. This way, you enhance the
                                        security of your payment data.</p><p>The minimum volume for sending to the EXPI
                                        transit wallet and conducting exchange operations is 50 USDT.</p><p>The maximum
                                        volume for sending to the EXPI transit wallet and conducting exchange operations
                                        is 100,000 USDT.</p><p>You have two transit wallets in EXPI bot, one for direct
                                        and automatic exchanges. You can work with them simultaneously.</p><p>You can
                                        send to the EXPI transit wallet with different amounts; when reaching the
                                        minimum volume for exchange of 50 USDT, you initiate the process of creating
                                        exchange operations in manual or automatic mode. </p><p>You can withdraw assets
                                        from the EXPI transit wallet to your personal address with different amounts at
                                        any time (after the completion of the current exchange operation). </p><p>Your
                                        EXPI transit address is designed so that you (in manual mode) or bots (in
                                        automatic mode) can seamlessly send and receive exchangeable assets, as well as
                                        accumulate profit from exchange operations.</p><p>If you sent an amount larger
                                        than the maximum to the EXPI transit wallet, you (in manual mode) or bots (in
                                        automatic mode) will be able to process the request with the maximum exchange
                                        amount of 100,000 USDT. The volume exceeding this amount will not be involved in
                                        exchange operations. </p><p>You (in manual mode) or bots (in automatic mode)
                                        cannot create multiple exchanges simultaneously; each exchange is processed
                                        according to the conditions of the REST API SdbS Exchange and REST API SdbS Pay
                                        protocols, and the next exchange will be available after completing the previous
                                        process.</p><p>The exchange service fixes the exchange rate at the moment of
                                        sending, so the exchange price always includes the coefficients of the CVI
                                        volatility indices. EXPI receives prices taking into account all possible
                                        commissions and risk indices. However, in the case of any insurmountable
                                        circumstances fixed by the exchange service on the exchanges in the exchange
                                        countries, due to which the exchange cannot take place in the format confirmed
                                        by you, the operation will be canceled and an immediate refund of the
                                        exchangeable asset to your transit wallet will be conducted, according to the
                                        PaaS (Alert) protocols, within 60 minutes.</p></div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">When can money be
                                withdrawn from the transit wallet?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>Withdrawal is available
                                    at any time, for amounts that are not currently involved in the exchange
                                    operation.</p><p>During the exchange operation, the withdrawal of the exchangeable
                                    amount is not possible. In automatic mode, you need to stop the auto-exchange and
                                    wait for the current exchange operation to finish. After completing the current
                                    exchange operation and returning the amount to the transit address, each user can
                                    withdraw any amounts located on their EXPI transit address.</p></div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">Why are there 10-minute
                                intervals before returning the exchanged asset to the transit address?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>This is a technical pause
                                    to ensure liquidity, which is logical and necessary for the normal operation and
                                    interaction of all objects and types of the aggregate system of exchange
                                    requests. </p><p>The Arbitration Strategy document section states that:</p><p>In
                                    order to ensure uninterrupted operation of our strategy for each client, regardless
                                    of the volume with which they conduct exchanges, we need to focus on ensuring our
                                    own liquidity to maintain the speed of operations at the required level. For this
                                    reason, and after the completion of the exchange, we fix the volume of the asset of
                                    each EXPI user for 10 minutes on the wallets of the exchange services. This volume
                                    is used to ensure a quick exchange so that all other user requests are processed on
                                    time, the fixation of the asset on the wallet of each exchange service lasts the
                                    specified 10 minutes, and your assets help other clients obtain a quick and
                                    profitable result.</p></div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">Is the transaction report
                                on exchange operations available?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>EXPI bot does not operate
                                    in the status of a statistical user of the SdbS Exchange exchange service.</p>
                                    <p> EXPI bot operates on a paid tariff with Paas Exchange and Saas Pay offers, in
                                        Rest API integration mode and adheres to the Terms of Use of the API of the
                                        licensed exchange provider SdbSUab.</p><p>According to the terms of these
                                        documents, the exchange service (SdbSUab) sends a report to the official email
                                        of the licensee (EXPI bot), which includes all data on exchange transactions
                                        (time, date, hash) in a consolidated format, available for tax reporting (PDF),
                                        volumes of all operations performed from EXPI bot transit addresses to the
                                        addresses of SdbS exchange services (56 objects), broken down by the domain
                                        names of these services.</p><p> The sending of consolidated financial
                                        information is conducted according to the request schedule for the period: once
                                        every 24 hours, once every 10 days, and once every 30 days. </p><p>Upon
                                        receiving transaction reports in the specified format, under the conditions of
                                        Rest API integration, EXPI bot does not have the capability to selectively
                                        provide payment information to each user. EXPI bot does not provide for the
                                        additional hiring of specialized staff for copywriting and selecting necessary
                                        data from reports for subsequent sending to each user for each exchange
                                        operation. </p><p> EXPI bot provides all users with direct broadcasts of each of
                                        their exchanges by the purchase and sale order IDs, with transition to EXPI API
                                        cabinets, on the websites of SdbS exchange service providers, in online mode, by
                                        transaction statuses, with the recording of the history of each exchange
                                        operation. According to the instantaneous responses of the REST API of SdbS
                                        Exchange crypto-exchange services by exchange operations and classification of
                                        the request type.</p><p>The conditions for sending reports are indicated at the
                                        address of the exchange provider SdbSUab in public access, on each of the 56
                                        exchange services. In the sections: </p><p>API Usage Conditions (item
                                        2.10); </p><p>Technical document of Rest API, in the Cachability section, it is
                                        stated: </p><p>'The response of the REST API of SdbS Exchange crypto-exchange
                                        services to exchange operations is instantaneous, classified by request type.
                                        Reports on the hashing of unique 'signatures' of financial data, in the form of
                                        transaction hashes, volumes, and quantities, are provided to the consumer
                                        according to established templates for classifying cryptocurrencies: once every
                                        24 hours, 10 days, 30 days. Exchange operation reports are recorded by the SdbS
                                        Exchange provider through blockchain network explorers and are provided for
                                        companies in a format accessible to the tax authority specified in the PaaS
                                        offer. Financial reports are sent to the email address of the company/consumer
                                        of REST API SdbS Exchange.'</p></div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">Who is SdbS Uab?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>SdbS Uab is a
                                    partner-licensor of EXPI bot.</p><p>The licensed over-the-counter exchange and
                                    crypto-payment acquiring service Swap DeFi Business System (SdbS) based in Latvia
                                    conducts legal activities and has a crypto license to provide cryptocurrency
                                    exchange services. The office is based in the business center in Vilnius, at
                                    Gedimino 20, office 38. The activities and charter capital (€125,000) of SdbS Uab
                                    are regulated by VASP/CASP, has coverage of exchange services in 56 countries,
                                    cooperates with 568 major crypto exchanges in the regions, processes more than 1500
                                    cryptocurrencies in payment operations.</p><p>In addition to crypto exchanges, SdbS
                                    Uab offers integrations of ResT API of exchange resource providers and
                                    crypto-merchant API, based on PaaS and SaaS offers.</p><p>Anyone can explore the
                                    activities and services of SdbS Uab; you can register and submit an application for
                                    consideration for integration of the 'Agreement on the Use of ResT API SdbS
                                    Exchange,' check the company and, after confirming the application, pay the tariff
                                    and gain access to the aggregate ResT API of personal cabinets on each of the
                                    fifty-six exchange resources and the crypto merchant cabinet. You can simultaneously
                                    use the API for exchange integration and the API for crypto payment integration if
                                    you provide services to a large number of clients.</p><p>The aggregate ResT API of
                                    SdbS Group is a paid service. The EXPI Bot service, located at https://expi.bot, is
                                    integrated via the SdbS Pay API protocols (Personal tariff) and SdbS Exchange API
                                    (Long-term tariff). The integration is confirmed by the PaaS License Agreement-offer
                                    on the integration of application programming interfaces of SdbS Exchange exchange
                                    services and the SaaS License Agreement-offer on the integration of application
                                    programming interfaces of the SdbS Pay crypto gateway.</p><p>SdbS Uab is a reliable
                                    global service that represents legal partnership with licensee provision, automated
                                    cryptocurrency exchanges, crypto payment processing, and other services for its
                                    users. EXPI Bot has been integrated with the services of SdbS Uab for more than 3
                                    months; the supply of exchanges and other payment solutions, in the request/response
                                    format, proceeds according to protocols in accelerated mode, liquidity is ensured in
                                    the stipulated volume, support and payment processing is performed instantly, the
                                    exchange provider and payment acquirer, SdbS Uab, fulfills the terms of offers and
                                    contracts with EXPI Bot at a high level.</p></div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">What are my risks?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>EXPI maximally minimizes
                                    your risks:</p>
                                    <ul>
                                        <li><p>1. Secure operations.</p><p>When you confirm the action 'Start Exchange'
                                            or activate 'Auto-Exchange,' the money located on your EXPI transit wallet
                                            is sent to the exchange service and returns after the operation is completed
                                            (according to the protocols of the aggregate REST API SdbS Exchange). This
                                            process is no different from the usual cryptocurrency exchange you use with
                                            the exchange service.<br/> In this case, EXPI provides you with the
                                            opportunity to use two exchange services simultaneously, with the existing
                                            price difference, to obtain a profitable exchange delta. At the same time,
                                            each exchange service fixes the price at the moment of sending; the exchange
                                            price always takes into account the coefficients of the CVI volatility
                                            indices and commissions. In case of any insurmountable circumstances, fixed
                                            by the exchange service on the exchanges in the exchange countries, due to
                                            which the exchange cannot take place in the format confirmed by you, the
                                            operation will be canceled and an immediate refund of the exchangeable asset
                                            to your transit wallet will be conducted, according to the terms of the PaaS
                                            offer protocols in Alert functions, within 60 minutes.</p></li>
                                        <li><p>2. Reliable partner.</p><p>The group of exchange services SdbS, with
                                            which EXPI works, is a reliable licensed partner, has coverage in 56
                                            countries, cooperates with 568 major crypto exchanges in the regions,
                                            processes more than 1500 cryptocurrencies in payment operations. It is not
                                            only an over-the-counter exchange service but also a crypto-payment
                                            acquiring service that provides extensive technology for business using
                                            various APIs and SaaS protocols. Based in Latvia, it has a crypto license to
                                            provide exchange and payment processing services in cryptocurrencies. The
                                            office is based in the business center in Vilnius, at Gedimino 20, office
                                            38. The activities and charter capital (€125,000) of SdbS Uab are regulated
                                            by VASP/CASP.</p></li>
                                        <li><p>3. Data protection in EXPI.</p><p>By exchanging with EXPI, you use assets
                                            for exchange operations throughout the subscription period. This means that
                                            the assets are effectively always protected. They are either in transit in
                                            the blockchain network or undergoing the exchange process on the exchange
                                            services. Additionally, your Transit Wallet and Private Key access to
                                            exchanges have several levels of protection:</p>
                                            <ol>
                                                <li>- You can link your EXPI transit wallet to your personal wallets by
                                                    adding them to the status of trusted addresses, thereby increasing
                                                    the security of fund withdrawals.
                                                </li>
                                                <li>- Your Private Key is already generated based on the principle of a
                                                    special hash function, developed for generating crypto wallets; it
                                                    is impossible to decrypt or guess its data. Additionally, you set a
                                                    reliable password and 2FA on it.
                                                </li>
                                            </ol>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">Why was my exchange
                                operation canceled?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>If your exchange
                                    operation is canceled and the assets are fully returned to the transit address, it
                                    means the system has protected your money from potential losses. </p><p>Yes, as we
                                    stated in our documents, exchange services fix the exchange price during the
                                    confirmation of any of the exchange operations, but in some cases, the market price
                                    may go out of control of the volatility indices applied by the exchange service.</p>
                                    <p>In the EXPI system, you simultaneously fix and confirm two exchange operations,
                                        so the amount of time spent on exchanges increases due to the transit of crypto
                                        assets from one exchange service to another. Considering this, we responsibly
                                        rely on risk indices. Therefore, when protocols specifically designed to fix an
                                        increased level of risk are triggered, an operation that may receive an
                                        undesirable and unprofitable result is instantly canceled and the asset is
                                        returned to the transit wallet.</p></div>
                            </li>
                            <li className="_item_1jvq2_23">
                                <h3 className="_itemTitle_1jvq2_28">Exchange ID - what is
                                it?</h3>
                                <div className="_itemContent_1jvq2_55" bis_skin_checked="1"><p>The SdbS network of
                                    exchangers instantly provides EXPI bot with the entire exchange process online, with
                                    the ability to go to the exchanger’s website via API.</p><p>By clicking on the order
                                    ID (purchase), you follow the link to the website of the exchange service of the
                                    country where you are currently exchanging, and see how your exchange for purchase
                                    is going on for the selected exchange pair, and the order ID (sale) guides you via
                                    an API connection to the process of transit from exchanger to exchanger and sale of
                                    your asset on the website of the exchanger of the selected country.</p><p>Thus, the
                                    SdbS exchange provider opens up this entire process, and on the EXPI bot resource we
                                    can provide all users with a live broadcast of each of their exchanges by purchase
                                    and sale order ID. The client can navigate via API to the exchange service sites of
                                    the SdbS exchange provider.</p><p>The translation of all exchange processes is
                                    indicated by the Terms of Use of the aggregate REST API SdbS (clause 2.10): each
                                    exchange service, each country of SdbS exchange (using a blockchain network browser)
                                    provides EXPI bot information on each exchange ID instantly online. Information on
                                    all exchange ID hash transactions carried out through the wallets of SdbS exchange
                                    services via the API with EXPI bot is combined according to the indicators of each
                                    exchange country and sent to the official EXPI bot email once every 24 hours in PDF
                                    format.</p></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </main>

    );
};

export default FaqPage;
