import React, {useEffect, useState} from 'react';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  HomePage,
  LoginPage,
  SubscriptionPage,
  PrivacyPolicyPage,
  TermsOfUsePage,
  AutomaticExchangesPage,
  DirectExchangesPage,
  OnlineExchangesPage,
  QualificationPage,
  ProgramPage,
  VideoInstructionPage,
  GetStartedPage,
  FundamentalSolutionPage,
  ArbitrationStrategyPage,
  InstructionsPage,
  FaqPage,
  PasswordPage,
  ReferralsPage,
  ResetPassword,
  Header, Footer
} from './components';

import { HelmetProvider } from 'react-helmet-async';
import Cookies from "js-cookie";
function App() {
  const [userDetails, setUserDetails] = useState(null);
  const [isGuest, setIsGuest] = useState(true);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = Cookies.get('token');
        const response = await fetch('https://expi.top:5005/user_details', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.ok) {
          const data = await response.json();
          setUserDetails(data);
        } else {
          console.error('Failed to fetch user details');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setIsGuest(false);
      }
    };

    if (!userDetails) {
      fetchUserDetails();
    }
  }, [userDetails]);

  useEffect(() => {
    console.log(userDetails);
  }, [userDetails]);

  return (
      <HelmetProvider>
        <Router>

            <Header userDetails={userDetails} isGuest={isGuest}/>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/us/login-user" element={<LoginPage/>} />
              <Route path="/us/subscription/get-v2" element={<SubscriptionPage userDetails={userDetails}/>} />
              <Route path="/us/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/us/terms-of-use" element={<TermsOfUsePage />} />
              <Route path="/us/exchanges/transit" element={<AutomaticExchangesPage userDetails={userDetails} />} />
              <Route path="/us/exchanges/direct" element={<DirectExchangesPage userDetails={userDetails}/>} />
              <Route path="/us/affiliate-programs/partners/online-exchanges" element={<OnlineExchangesPage />} />
              <Route path="/us/affiliate-programs/partners/qualification" element={<QualificationPage />} />
              <Route path="/us/affiliate-programs/partners/program" element={<ProgramPage />} />
              <Route path="/us/video-instruction" element={<VideoInstructionPage />} />
              <Route path="/us/get-started" element={<GetStartedPage />} />
              <Route path="/us/fundamental-solution" element={<FundamentalSolutionPage userDetails={userDetails}/>} />
              <Route path="/us/arbitration-strategy" element={<ArbitrationStrategyPage userDetails={userDetails}/>} />
              <Route path="/us/instructions" element={<InstructionsPage />} />
              <Route path="/us/faq" element={<FaqPage />} />
              <Route path="/us/security/password" element={<PasswordPage userDetails={userDetails}/>} />
              <Route path="/us/security/reset-password" element={<ResetPassword userDetails={userDetails}/>} />
              <Route path="/us/affiliate-programs/referrals/history" element={<ReferralsPage userDetails={userDetails}/>} />

            </Routes>
            <Footer/>
          <ToastContainer />
        </Router>
      </HelmetProvider>
  );
}

export default App;
