import React from 'react';

import step1 from '../startedScreens/step1.png';


import {Link} from "react-router-dom";


const ResetPassword = () => {


    return (
    <main>
        <div class="_container_4214v_15" bis_skin_checked="1">
            <div class="_container_agdo9_1" bis_skin_checked="1">
                <div class="_pageTitle_agdo9_7" bis_skin_checked="1">Password</div>
                <div class="_content_agdo9_14" bis_skin_checked="1">
                    <div class="_note_agdo9_21" bis_skin_checked="1"><b>Password Recovery</b></div>
                    <form>
                        <div class="_formInput_1dpdj_1" bis_skin_checked="1">
                            <div class="_md_1wje8_78" bis_skin_checked="1"><label class="_label_1wje8_1"><span
                                class="_labelText_1wje8_29">A link to reset your password will be sent to your E-mail</span>
                                <div class="_inputContainer_1wje8_44" bis_skin_checked="1"><input
                                    class="_input_1wje8_7 " type="email" name="email"/></div>
                            </label></div>
                        </div>

                        <div class="_formButton_1dpdj_5" bis_skin_checked="1">
                            <button type="submit" class="_primary_1237m_41 _md_1237m_33 _button_1237m_1">Continue
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
)
};

export default ResetPassword;
