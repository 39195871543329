import React from 'react';

import logomain from '../img/landing/logoMain.png';


const HomePageBlockHero = () => {


    return (

        <div class="_topPageContainer_njvc2_1" bis_skin_checked="1">
            <div bis_skin_checked="1">
                <div class="_topPageContent_njvc2_68" bis_skin_checked="1">
                    <div class="_rightTopPageContent_njvc2_183" bis_skin_checked="1"><img
                        src={logomain} alt=""/></div>
                    <div class="_leftTopPageContent_njvc2_18" bis_skin_checked="1"><h1
                        class="_topPageTitle_njvc2_43">EXPI - AUTOMATIC AND DIRECT EXCHANGES</h1><h2
                        class="_topPageSubtitle_njvc2_85">Arbitration Solution</h2>
                        <ul class="_topPageList_njvc2_108">
                            <li>integration with 56 exchange services</li>
                            <li>over 568 markets included by country</li>
                            <li>from 36 exchange operations per day</li>
                            <li>arbitrage delta from 33.7% per month</li>
                        </ul>
                        <div class="_topPageBtn_njvc2_148" bis_skin_checked="1"><a href="/en/register-user"
                                                                                   bis_skin_checked="1">
                            <button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                     fill="none">
                                    <path
                                        d="M18 5.67689C18 4.9105 17.846 4.16779 17.5445 3.46598C17.2518 2.79001 16.8337 2.18292 16.3012 1.66195C15.7687 1.14098 15.1482 0.731947 14.4572 0.445626C13.7421 0.148542 12.9807 0 12.1973 0C11.414 0 10.6548 0.150695 9.93744 0.445626C9.24651 0.731947 8.62596 1.14098 8.09344 1.66195C7.56092 2.18292 7.14283 2.79001 6.85017 3.46598C6.5465 4.16564 6.39467 4.9105 6.39467 5.67689C6.39467 6.63488 6.64112 7.5735 7.10982 8.40878L0.16291 15.1987C0.00227452 15.3558 -0.0483365 15.5948 0.0506851 15.8208C0.0704894 15.8682 0.103497 15.9112 0.140905 15.9478L1.69664 17.4698C1.73405 17.5064 1.77806 17.5366 1.82647 17.5581C2.05752 17.655 2.30178 17.6076 2.46241 17.4483L3.42402 16.5075L4.80812 17.8616C4.84553 17.8982 4.88954 17.9284 4.93793 17.9499C5.16898 18.0468 5.41326 17.9994 5.57387 17.8401L7.1076 16.3396C7.14501 16.303 7.17582 16.26 7.19782 16.2126C7.29684 15.9866 7.24843 15.7476 7.0856 15.5905L5.7235 14.2579L9.4027 10.6563C10.2543 11.1148 11.2159 11.3559 12.1951 11.3559C12.9785 11.3559 13.7376 11.2052 14.455 10.9103C15.1459 10.624 15.7665 10.215 16.299 9.69399C16.8315 9.17304 17.2496 8.56595 17.5423 7.88996C17.846 7.186 18 6.44329 18 5.67689ZM14.2306 7.66822C13.6871 8.19996 12.9631 8.49274 12.1952 8.49274C11.4272 8.49274 10.7032 8.19996 10.1597 7.66822C9.61619 7.13648 9.31473 6.42822 9.31473 5.67689C9.31473 4.92342 9.61399 4.21731 10.1575 3.68557C10.701 3.15383 11.425 2.86105 12.193 2.86105C12.9609 2.86105 13.6849 3.15383 14.2284 3.68557C14.7719 4.21731 15.0712 4.92557 15.0712 5.67689C15.0756 6.42822 14.7763 7.13648 14.2306 7.66822Z"
                                        fill="white"></path>
                                </svg>
                                <p>Quick Access</p></button>
                        </a></div>
                    </div>
                </div>
            </div>
        </div>


);
};

export default HomePageBlockHero;
