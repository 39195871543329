import React from 'react';

import videoInstructionsScreensReg from '../videoInstructionsScreens/reg.png';
import videoInstructionsScreensDepUpd from '../videoInstructionsScreens/depositupd.png';
import videoInstructionsScreensAutoExch from '../videoInstructionsScreens/auto-exch.png';
import videoInstructionsScreensdirectexchupd from '../videoInstructionsScreens/directexchupd.png';
import videoInstructionsScreenstrasredaddressupd from '../videoInstructionsScreens/trasredaddressupd.png';
import videoInstructionsScreenswithdrawalupd from '../videoInstructionsScreens/withdrawalupd.png';
import videoInstructionsScreenpasswordupd from '../videoInstructionsScreens/passwordupd.png';
import videoInstructionsScreen2faupd from '../videoInstructionsScreens/2faupd.png';


import {Link} from "react-router-dom";


const VideoInstructionPage = () => {


    return (

            <main>
                <div className="_container_4214v_15" bis_skin_checked="1">
                    <div className="_videoInstructionWrapper_1vcpv_1" bis_skin_checked="1">
                        <div className="_container_1vcpv_6" bis_skin_checked="1">
                            <div className="_wrapper_1vcpv_27" bis_skin_checked="1"><h1
                                className="_main_title_1vcpv_13">Video Tutorial</h1>
                                <div bis_skin_checked="1">
                                    <div className="_wrapper_blocks_1vcpv_58" bis_skin_checked="1">
                                        <div className="_wrap_blocks_1vcpv_69" bis_skin_checked="1">
                                            <div className="_blocks_1vcpv_93" bis_skin_checked="1">
                                                <a target="_blank" href="https://www.youtube.com/watch?v=gYNPRWVe1j0&amp;list=PL4J9CW-I8REUxoaxFmZblcbodb6hWUDdZ&amp;ab_channel=EXPI%7COfficial">
                                                    <img  loading="eager" src={videoInstructionsScreensReg} alt=""/>
                                                <div className="_video_bottom_info_1vcpv_144" bis_skin_checked="1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                                                         viewBox="0 0 31 31" fill="none">
                                                        <rect x="0.772461" y="0.552979" width="29.934" height="30"
                                                              fill="#50C878"></rect>
                                                        <path
                                                            d="M18.8325 14.8355L19.0662 15.1925C20.5652 17.5797 21.604 19.2084 22.1828 20.0785C22.7616 20.9486 23.6224 22.1794 24.7652 23.7709H16.84L12.9888 17.4905C11.7867 18.9184 10.3953 20.7367 8.81475 22.9454C8.51793 23.3545 8.29531 23.6296 8.1469 23.7709C7.99849 23.9122 7.85008 23.9829 7.70167 23.9829C7.43453 23.9829 7.30096 23.8602 7.30096 23.6148C7.30096 23.5181 7.32693 23.4251 7.37888 23.3359C7.43824 23.2392 7.6423 22.9715 7.99107 22.5327L8.38065 22.053L12.5436 16.7988L7.21191 8.54388H15.0257L18.3984 14.1439C19.1924 13.1696 20.3203 11.6711 21.7821 9.64826C22.2125 9.04587 22.4908 8.67774 22.6169 8.54388C22.7505 8.40257 22.8915 8.33192 23.0399 8.33192C23.1586 8.33192 23.2625 8.37283 23.3516 8.45463C23.4406 8.53644 23.4851 8.63312 23.4851 8.74467C23.4851 8.88597 23.4221 9.04215 23.2959 9.2132C23.1772 9.38425 22.7542 9.9197 22.027 10.8196L18.8325 14.8355Z"
                                                            fill="white"></path>
                                                    </svg>
                                                    <h2 className="_video_title_1vcpv_166">HOW TO REGISTER</h2>
                                                </div>
                                            </a>
                                                <a target="_blank"  href="https://www.youtube.com/watch?v=g13xFcQoKEc&amp;list=PL4J9CW-I8REUxoaxFmZblcbodb6hWUDdZ&amp;index=2&amp;ab_channel=EXPI%7COfficial">
                                                    <img loading="eager" src={videoInstructionsScreensDepUpd} alt=""/>
                                                <div className="_video_bottom_info_1vcpv_144" bis_skin_checked="1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                                                         viewBox="0 0 31 31" fill="none">
                                                        <rect x="0.772461" y="0.552979" width="29.934" height="30"
                                                              fill="#50C878"></rect>
                                                        <path
                                                            d="M18.8325 14.8355L19.0662 15.1925C20.5652 17.5797 21.604 19.2084 22.1828 20.0785C22.7616 20.9486 23.6224 22.1794 24.7652 23.7709H16.84L12.9888 17.4905C11.7867 18.9184 10.3953 20.7367 8.81475 22.9454C8.51793 23.3545 8.29531 23.6296 8.1469 23.7709C7.99849 23.9122 7.85008 23.9829 7.70167 23.9829C7.43453 23.9829 7.30096 23.8602 7.30096 23.6148C7.30096 23.5181 7.32693 23.4251 7.37888 23.3359C7.43824 23.2392 7.6423 22.9715 7.99107 22.5327L8.38065 22.053L12.5436 16.7988L7.21191 8.54388H15.0257L18.3984 14.1439C19.1924 13.1696 20.3203 11.6711 21.7821 9.64826C22.2125 9.04587 22.4908 8.67774 22.6169 8.54388C22.7505 8.40257 22.8915 8.33192 23.0399 8.33192C23.1586 8.33192 23.2625 8.37283 23.3516 8.45463C23.4406 8.53644 23.4851 8.63312 23.4851 8.74467C23.4851 8.88597 23.4221 9.04215 23.2959 9.2132C23.1772 9.38425 22.7542 9.9197 22.027 10.8196L18.8325 14.8355Z"
                                                            fill="white"></path>
                                                    </svg>
                                                    <h2 className="_video_title_1vcpv_166">HOW TO MAKE A DEPOSIT ON YOUR
                                                        ACCOUNT</h2></div>
                                            </a></div>
                                            <div className="_blocks_1vcpv_93" bis_skin_checked="1">
                                                <a target="_blank" href="https://www.youtube.com/watch?v=3zSlbrqFKgI&amp;list=PL4J9CW-I8REUxoaxFmZblcbodb6hWUDdZ&amp;index=4&amp;ab_channel=EXPI%7COfficial">
                                                    <img loading="eager" src={videoInstructionsScreensAutoExch}  alt=""/>
                                                <div className="_video_bottom_info_1vcpv_144" bis_skin_checked="1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                                                         viewBox="0 0 31 31" fill="none">
                                                        <rect x="0.772461" y="0.552979" width="29.934" height="30"
                                                              fill="#50C878"></rect>
                                                        <path
                                                            d="M18.8325 14.8355L19.0662 15.1925C20.5652 17.5797 21.604 19.2084 22.1828 20.0785C22.7616 20.9486 23.6224 22.1794 24.7652 23.7709H16.84L12.9888 17.4905C11.7867 18.9184 10.3953 20.7367 8.81475 22.9454C8.51793 23.3545 8.29531 23.6296 8.1469 23.7709C7.99849 23.9122 7.85008 23.9829 7.70167 23.9829C7.43453 23.9829 7.30096 23.8602 7.30096 23.6148C7.30096 23.5181 7.32693 23.4251 7.37888 23.3359C7.43824 23.2392 7.6423 22.9715 7.99107 22.5327L8.38065 22.053L12.5436 16.7988L7.21191 8.54388H15.0257L18.3984 14.1439C19.1924 13.1696 20.3203 11.6711 21.7821 9.64826C22.2125 9.04587 22.4908 8.67774 22.6169 8.54388C22.7505 8.40257 22.8915 8.33192 23.0399 8.33192C23.1586 8.33192 23.2625 8.37283 23.3516 8.45463C23.4406 8.53644 23.4851 8.63312 23.4851 8.74467C23.4851 8.88597 23.4221 9.04215 23.2959 9.2132C23.1772 9.38425 22.7542 9.9197 22.027 10.8196L18.8325 14.8355Z"
                                                            fill="white"></path>
                                                    </svg>
                                                    <h2 className="_video_title_1vcpv_166">HOW TO START
                                                        AUTO-EXCHANGE</h2></div>
                                            </a>
                                                <a target="_blank"  href="https://www.youtube.com/watch?v=wCzzWVOYrQ0&amp;list=PL4J9CW-I8REUxoaxFmZblcbodb6hWUDdZ&amp;index=3&amp;ab_channel=EXPI%7COfficial">
                                                    <img loading="eager" src={videoInstructionsScreensdirectexchupd} alt=""/>
                                                <div className="_video_bottom_info_1vcpv_144" bis_skin_checked="1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                                                         viewBox="0 0 31 31" fill="none">
                                                        <rect x="0.772461" y="0.552979" width="29.934" height="30"
                                                              fill="#50C878"></rect>
                                                        <path
                                                            d="M18.8325 14.8355L19.0662 15.1925C20.5652 17.5797 21.604 19.2084 22.1828 20.0785C22.7616 20.9486 23.6224 22.1794 24.7652 23.7709H16.84L12.9888 17.4905C11.7867 18.9184 10.3953 20.7367 8.81475 22.9454C8.51793 23.3545 8.29531 23.6296 8.1469 23.7709C7.99849 23.9122 7.85008 23.9829 7.70167 23.9829C7.43453 23.9829 7.30096 23.8602 7.30096 23.6148C7.30096 23.5181 7.32693 23.4251 7.37888 23.3359C7.43824 23.2392 7.6423 22.9715 7.99107 22.5327L8.38065 22.053L12.5436 16.7988L7.21191 8.54388H15.0257L18.3984 14.1439C19.1924 13.1696 20.3203 11.6711 21.7821 9.64826C22.2125 9.04587 22.4908 8.67774 22.6169 8.54388C22.7505 8.40257 22.8915 8.33192 23.0399 8.33192C23.1586 8.33192 23.2625 8.37283 23.3516 8.45463C23.4406 8.53644 23.4851 8.63312 23.4851 8.74467C23.4851 8.88597 23.4221 9.04215 23.2959 9.2132C23.1772 9.38425 22.7542 9.9197 22.027 10.8196L18.8325 14.8355Z"
                                                            fill="white"></path>
                                                    </svg>
                                                    <h2 className="_video_title_1vcpv_166">HOW TO START DIRECT
                                                        EXCHANGE</h2></div>
                                            </a></div>
                                        </div>
                                        <div className="_wrap_blocks_1vcpv_69" bis_skin_checked="1">
                                            <div className="_blocks_1vcpv_93" bis_skin_checked="1">
                                                <a target="_blank"  href="https://www.youtube.com/watch?v=pZuyQ4qJDx8&amp;list=PL4J9CW-I8REUxoaxFmZblcbodb6hWUDdZ&amp;index=7&amp;ab_channel=EXPI%7COfficial">
                                                    <img loading="eager" src={videoInstructionsScreenstrasredaddressupd} alt=""/>
                                                <div className="_video_bottom_info_1vcpv_144" bis_skin_checked="1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                                                         viewBox="0 0 31 31" fill="none">
                                                        <rect x="0.772461" y="0.552979" width="29.934" height="30"
                                                              fill="#50C878"></rect>
                                                        <path
                                                            d="M18.8325 14.8355L19.0662 15.1925C20.5652 17.5797 21.604 19.2084 22.1828 20.0785C22.7616 20.9486 23.6224 22.1794 24.7652 23.7709H16.84L12.9888 17.4905C11.7867 18.9184 10.3953 20.7367 8.81475 22.9454C8.51793 23.3545 8.29531 23.6296 8.1469 23.7709C7.99849 23.9122 7.85008 23.9829 7.70167 23.9829C7.43453 23.9829 7.30096 23.8602 7.30096 23.6148C7.30096 23.5181 7.32693 23.4251 7.37888 23.3359C7.43824 23.2392 7.6423 22.9715 7.99107 22.5327L8.38065 22.053L12.5436 16.7988L7.21191 8.54388H15.0257L18.3984 14.1439C19.1924 13.1696 20.3203 11.6711 21.7821 9.64826C22.2125 9.04587 22.4908 8.67774 22.6169 8.54388C22.7505 8.40257 22.8915 8.33192 23.0399 8.33192C23.1586 8.33192 23.2625 8.37283 23.3516 8.45463C23.4406 8.53644 23.4851 8.63312 23.4851 8.74467C23.4851 8.88597 23.4221 9.04215 23.2959 9.2132C23.1772 9.38425 22.7542 9.9197 22.027 10.8196L18.8325 14.8355Z"
                                                            fill="white"></path>
                                                    </svg>
                                                    <h2 className="_video_title_1vcpv_166">HOW TO SET UP TRUSTED
                                                        ADDRESS</h2></div>
                                            </a>
                                                <a target="_blank" href="https://www.youtube.com/watch?v=l1tLxvNblSU&amp;list=PL4J9CW-I8REUxoaxFmZblcbodb6hWUDdZ&amp;index=8&amp;ab_channel=EXPI%7COfficial">
                                                    <img loading="eager" src={videoInstructionsScreenswithdrawalupd} alt=""/>
                                                <div className="_video_bottom_info_1vcpv_144" bis_skin_checked="1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                                                         viewBox="0 0 31 31" fill="none">
                                                        <rect x="0.772461" y="0.552979" width="29.934" height="30"
                                                              fill="#50C878"></rect>
                                                        <path
                                                            d="M18.8325 14.8355L19.0662 15.1925C20.5652 17.5797 21.604 19.2084 22.1828 20.0785C22.7616 20.9486 23.6224 22.1794 24.7652 23.7709H16.84L12.9888 17.4905C11.7867 18.9184 10.3953 20.7367 8.81475 22.9454C8.51793 23.3545 8.29531 23.6296 8.1469 23.7709C7.99849 23.9122 7.85008 23.9829 7.70167 23.9829C7.43453 23.9829 7.30096 23.8602 7.30096 23.6148C7.30096 23.5181 7.32693 23.4251 7.37888 23.3359C7.43824 23.2392 7.6423 22.9715 7.99107 22.5327L8.38065 22.053L12.5436 16.7988L7.21191 8.54388H15.0257L18.3984 14.1439C19.1924 13.1696 20.3203 11.6711 21.7821 9.64826C22.2125 9.04587 22.4908 8.67774 22.6169 8.54388C22.7505 8.40257 22.8915 8.33192 23.0399 8.33192C23.1586 8.33192 23.2625 8.37283 23.3516 8.45463C23.4406 8.53644 23.4851 8.63312 23.4851 8.74467C23.4851 8.88597 23.4221 9.04215 23.2959 9.2132C23.1772 9.38425 22.7542 9.9197 22.027 10.8196L18.8325 14.8355Z"
                                                            fill="white"></path>
                                                    </svg>
                                                    <h2 className="_video_title_1vcpv_166">HOW TO WITHDRAW</h2></div>
                                            </a></div>
                                            <div className="_blocks_1vcpv_93" bis_skin_checked="1">
                                                <a target="_blank"  href="https://www.youtube.com/watch?v=MppAhZpm9to&amp;list=PL4J9CW-I8REUxoaxFmZblcbodb6hWUDdZ&amp;index=5&amp;ab_channel=EXPI%7COfficial">
                                                    <img loading="eager" src={videoInstructionsScreenpasswordupd}
                                                alt=""/>
                                                <div className="_video_bottom_info_1vcpv_144" bis_skin_checked="1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                                                         viewBox="0 0 31 31" fill="none">
                                                        <rect x="0.772461" y="0.552979" width="29.934" height="30"
                                                              fill="#50C878"></rect>
                                                        <path
                                                            d="M18.8325 14.8355L19.0662 15.1925C20.5652 17.5797 21.604 19.2084 22.1828 20.0785C22.7616 20.9486 23.6224 22.1794 24.7652 23.7709H16.84L12.9888 17.4905C11.7867 18.9184 10.3953 20.7367 8.81475 22.9454C8.51793 23.3545 8.29531 23.6296 8.1469 23.7709C7.99849 23.9122 7.85008 23.9829 7.70167 23.9829C7.43453 23.9829 7.30096 23.8602 7.30096 23.6148C7.30096 23.5181 7.32693 23.4251 7.37888 23.3359C7.43824 23.2392 7.6423 22.9715 7.99107 22.5327L8.38065 22.053L12.5436 16.7988L7.21191 8.54388H15.0257L18.3984 14.1439C19.1924 13.1696 20.3203 11.6711 21.7821 9.64826C22.2125 9.04587 22.4908 8.67774 22.6169 8.54388C22.7505 8.40257 22.8915 8.33192 23.0399 8.33192C23.1586 8.33192 23.2625 8.37283 23.3516 8.45463C23.4406 8.53644 23.4851 8.63312 23.4851 8.74467C23.4851 8.88597 23.4221 9.04215 23.2959 9.2132C23.1772 9.38425 22.7542 9.9197 22.027 10.8196L18.8325 14.8355Z"
                                                            fill="white"></path>
                                                    </svg>
                                                    <h2 className="_video_title_1vcpv_166">HOW TO CREATE A PASSWORD</h2>
                                                </div>
                                            </a><a target="_blank"
                                                   href="https://www.youtube.com/watch?v=fpi-bSSIz38&amp;list=PL4J9CW-I8REUxoaxFmZblcbodb6hWUDdZ&amp;index=6&amp;ab_channel=EXPI%7COfficial">
                                                <img loading="eager" src={videoInstructionsScreen2faupd} alt=""/>
                                                <div className="_video_bottom_info_1vcpv_144" bis_skin_checked="1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                                                         viewBox="0 0 31 31" fill="none">
                                                        <rect x="0.772461" y="0.552979" width="29.934" height="30"
                                                              fill="#50C878"></rect>
                                                        <path
                                                            d="M18.8325 14.8355L19.0662 15.1925C20.5652 17.5797 21.604 19.2084 22.1828 20.0785C22.7616 20.9486 23.6224 22.1794 24.7652 23.7709H16.84L12.9888 17.4905C11.7867 18.9184 10.3953 20.7367 8.81475 22.9454C8.51793 23.3545 8.29531 23.6296 8.1469 23.7709C7.99849 23.9122 7.85008 23.9829 7.70167 23.9829C7.43453 23.9829 7.30096 23.8602 7.30096 23.6148C7.30096 23.5181 7.32693 23.4251 7.37888 23.3359C7.43824 23.2392 7.6423 22.9715 7.99107 22.5327L8.38065 22.053L12.5436 16.7988L7.21191 8.54388H15.0257L18.3984 14.1439C19.1924 13.1696 20.3203 11.6711 21.7821 9.64826C22.2125 9.04587 22.4908 8.67774 22.6169 8.54388C22.7505 8.40257 22.8915 8.33192 23.0399 8.33192C23.1586 8.33192 23.2625 8.37283 23.3516 8.45463C23.4406 8.53644 23.4851 8.63312 23.4851 8.74467C23.4851 8.88597 23.4221 9.04215 23.2959 9.2132C23.1772 9.38425 22.7542 9.9197 22.027 10.8196L18.8325 14.8355Z"
                                                            fill="white"></path>
                                                    </svg>
                                                    <h2 className="_video_title_1vcpv_166">HOW TO SET UP TWO FACTOR
                                                        AUTHENTICATION</h2></div>
                                            </a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

    );
};

export default VideoInstructionPage;
