import React from 'react';

import logomain from '../img/landing/logoMain.png';
import {HomePageBlockFooter} from "./index";
import {Link} from "react-router-dom";


const Footer = () => {


    return (

        <footer>
            <div className="_container_1fsqz_5" bis_skin_checked="1">
                <div className="_text_1fsqz_28" bis_skin_checked="1">
                    <div className="_logo_1fsqz_41" bis_skin_checked="1"><a aria-current="page" className="active"
                                                                            href="/">Expi<span>Bot / SdbS</span>Uab</a>
                    </div>
                    <div className="_copyright_1fsqz_61" bis_skin_checked="1">2024 All rights reserved</div>
                </div>
                <div className="_licensor_1fsqz_77" bis_skin_checked="1"><a className="_licensorLink_1fsqz_90"
                                                                            href="https://sdbs-us.com/business/account/partners"
                                                                            bis_skin_checked="1">Licensor</a></div>
                <nav className="_nav_1fsqz_112">
                    <ul className="_navList_1fsqz_122">
                        <li>
                            <Link className="_navLink_1fsqz_148" to="/us/privacy-policy" bis_skin_checked="1">
                                <span  className="_icon_1fsqz_154">
                                <svg width="12" height="15" viewBox="0 0 12 15" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg"><path
                            d="M11.1429 0.0410156H0.857245C0.377295 0.0410156 0 0.425811 0 0.915947V5.8859C0 9.56102 2.29727 12.8333 5.7259 13.9884C5.81144 14.0236 5.91428 14.041 6.00018 14.041C6.0859 14.041 6.18856 14.0236 6.27428 13.9884C9.7029 12.8333 12 9.56102 12 5.8859V0.915947C12 0.425811 11.6231 0.0410156 11.1429 0.0410156ZM9.25735 4.94092L6.6858 8.44102C6.53146 8.65097 6.30868 8.77359 6.06879 8.79106H6.00018C5.77705 8.79106 5.55427 8.70337 5.40011 8.52835L3.68597 6.7783C3.34308 6.4459 3.34308 5.8859 3.68597 5.5535C4.01158 5.20346 4.56015 5.20346 4.88576 5.5535L5.91428 6.58599L7.88576 3.89097C8.17715 3.50599 8.70861 3.43595 9.08572 3.71595C9.46302 4.01341 9.54874 4.55595 9.25735 4.94092Z"
                            fill="#2EB15A"></path></svg></span><span
                            className="_linkText_1fsqz_167">Privacy Policy</span></Link></li>
                        <li>
                            <Link className="_navLink_1fsqz_148" to="/us/terms-of-use" bis_skin_checked="1"><span
                            className="_icon_1fsqz_154"><svg width="12" height="15" viewBox="0 0 12 15" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg"><path
                            d="M1.74 0.0439453L0.00268555 1.78126H1.74V0.0439453Z" fill="#2EB15A"></path><path
                            d="M9.04771 0.0410156H2.56033V2.60135H0V11.9847H9.04774L9.04771 0.0410156ZM7.35823 7.96627H1.68951V7.14596H7.35823V7.96627ZM7.35823 6.22354H1.68951V5.40323H7.35823V6.22354ZM7.35823 4.48079H1.68951V3.66048H7.35823V4.48079Z"
                            fill="#2EB15A"></path><path
                            d="M9.86787 2.09717V12.8049H2.05615V14.0409H11.1039V2.09717H9.86787Z" fill="#2EB15A"></path></svg></span><span
                            className="_linkText_1fsqz_167">Terms of Use</span></Link></li>
                    </ul>
                </nav>
                <div className="_support_1fsqz_200" bis_skin_checked="1">
                    <div className="_supportDropdown_16ik4_1" bis_skin_checked="1">
                        <button type="button"
                                className="_outline_1237m_69 _md_1237m_33 _button_1237m_1 _rightIcon_1237m_18 _w100_1237m_22">Support
                            24/7<span className="_caret_16ik4_27"><svg width="6" height="6" viewBox="0 0 6 6"
                                                                       fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                d="M0.834961 0.472656C0.598145 0.472656 0.498047 0.599609 0.498047 0.746094C0.498047 0.807129 0.517578 0.873047 0.551758 0.936523L2.91016 5.02588C2.96875 5.12598 3.07373 5.1748 3.18359 5.1748C3.29102 5.1748 3.39844 5.12598 3.45459 5.02588L5.81543 0.936523C5.84961 0.873047 5.8667 0.807129 5.8667 0.746094C5.8667 0.599609 5.7666 0.472656 5.52979 0.472656H0.834961Z"
                                fill="black"></path></svg></span></button>
                    </div>
                </div>
            </div>
        </footer>

    );
};

export default Footer;
