import React, {useEffect, useState} from 'react';

import usdtTokenIcon from '../tokens/usdt.svg';
import btcTokenIcon from '../tokens/btc.svg';
import { QRCodeCanvas } from 'qrcode.react';
import {Link, NavLink} from "react-router-dom";

import { AsideLeft,AsideRight } from "./";
import {toast} from "react-toastify";

const DirectExchangesPage = ({userDetails}) => {
    const [depositModal, setDepositModal] = useState(false);
    const hideDepositModal = () => setDepositModal(false);
    const showDepositModal = () => setDepositModal(true);

    const shortenWallet = (wallet) => {
        if (!wallet) return '';
        return `${wallet.substring(0, 4)}...${wallet.substring(wallet.length - 4)}`;
    };

    const [activeSection, setActiveSection] = useState('matic');
    const handleSectionClick = (section) => setActiveSection(section);


    const [selectedCoin, setSelectedCoin] = useState('BTC');
    const coins = ["ETH", "LTC", "BTC", "ADA", "XRP", "SOL", "DOGE", "TRX", "DOT", "BCH", "BTG", "ETC", "XLM", "MATIC", "NEAR", "SUI", "ZEC", "DASH"];


    const importAll = (r) => {
        let images = {};
        r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    };

    const images = importAll(require.context('../tokens', false, /\.(png|jpe?g|svg)$/));
    const imagesflags = importAll(require.context('../flags', false, /\.(png|jpe?g|svg)$/));
    const textToSmall = (text) => text.toLowerCase();
    const smallSelectedCoin = textToSmall(selectedCoin);



    const handleCoinSelection = (coin) => setSelectedCoin(coin);
    const [menuCoinIsShown, setMenuCoinIsShown] = useState(false);
    const toggleMenuCoin = () => setMenuCoinIsShown(prevState => !prevState);

    const [coinPrices, setCoinPrices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [leftActiveItem, setLeftActiveItem] = useState(null);
    const [rightActiveItem, setRightActiveItem] = useState(null);
    const [startInvest, setStartInvest] = useState('1000');
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        const fetchCoinPrices = async () => {
            try {
                const response = await fetch('https://expi.top:5005/coinprice');
                const data = await response.json();

                setCoinPrices(data);
                setLoading(false);
                // Set the first item as active for both left and right
                const leftItems = [...data].sort((a, b) => a[selectedCoin] - b[selectedCoin]);
                const rightItems = [...data].sort((a, b) => b[selectedCoin] - a[selectedCoin]);
                setLeftActiveItem(leftItems[0]);
                setRightActiveItem(rightItems[0]);
            } catch (error) {
                setLoading(false);
            }
        };

        fetchCoinPrices();
    }, []);

    const handleLeftItemClick = (item) => {
        setLeftActiveItem(item);
    };

    const handleRightItemClick = (item) => {
        setRightActiveItem(item);
    };

    if (loading) {
        return <div className="_wrapper_9rny5_1" bis_skin_checked="1"><div className="_loader_9rny5_11" bis_skin_checked="1"></div>
        </div>;
    }
    const leftItems = [...coinPrices].sort((a, b) => a[selectedCoin] - b[selectedCoin]);
    const rightItems = [...coinPrices].sort((a, b) => b[selectedCoin] - a[selectedCoin]);

    const handleInvestChange = (e) => {

        const value = e.target.value;

        if (value > 100000) {
            setStartInvest(100000);
        } else {
            setStartInvest(value);
        }

    };
    const youGive = startInvest / leftActiveItem[selectedCoin] ;
    const youGet = youGive * rightActiveItem[selectedCoin];
    const profit = youGet - startInvest;

    const roundToTwoDecimalPlaces = (num) => {
        return Math.round(num * 100) / 100;
    };
    const setMax= (e) => {
        setStartInvest(100000);

    };
    const copyToClipboard = () => {
        const wallet = document.getElementById('wallettocopy');
        navigator.clipboard.writeText(wallet.textContent).then(() => {
            setCopied(true);
            toast.success(wallet.textContent + ' - Address successfully copied', {
                position: 'top-right',
                autoClose: 3000
            });

        }).catch(err => {
            setCopied(false);
            console.error('Failed to copy: ', err);
        });
    };

    return (

        <div className="_container_4214v_15" bis_skin_checked="1">
            <div className="_container_j0ym2_1" bis_skin_checked="1">

                <AsideLeft items={leftItems}
                           selectedCoin={selectedCoin}
                           activeItem={leftActiveItem}
                           onItemClick={handleLeftItemClick}  />
                <div className="_pairSelector_j0ym2_38" bis_skin_checked="1">
                    <div className="_pairSelectorContent_j0ym2_91" bis_skin_checked="1">
                        <div className="_pair_j0ym2_38" bis_skin_checked="1">
                            <div className="_title_j0ym2_102" bis_skin_checked="1">Exchange Pairs</div>
                            <div className="_pairSelect_j0ym2_38" bis_skin_checked="1">
                                <div className="_pairSelect_14o74_1" bis_skin_checked="1">
                                    <button onClick={toggleMenuCoin} className="_pairLabel_14o74_8" type="button"><span>USDT-{selectedCoin}</span><span
                                        class="_arrow_14o74_21"><svg width="6" height="6" viewBox="0 0 6 6" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg"><path
                                        d="M5.3584 0.901367C5.3584 1.03027 5.30566 1.13574 5.23242 1.28516L3.45117 4.95312C3.32227 5.21973 3.17578 5.33105 2.97949 5.33105C2.78027 5.33105 2.63379 5.21973 2.50488 4.95312L0.723633 1.28516C0.650391 1.13281 0.597656 1.02734 0.597656 0.898438C0.597656 0.649414 0.788086 0.464844 1.09863 0.464844H4.85742C5.16797 0.464844 5.3584 0.652344 5.3584 0.901367ZM4.54688 1.15039C4.54688 1.13574 4.53516 1.12402 4.51465 1.12402L1.44141 1.12109C1.4209 1.12109 1.40918 1.13281 1.40918 1.14746C1.40918 1.15918 1.41504 1.1709 1.4209 1.18262L2.94727 4.40234C2.95605 4.41699 2.96484 4.42578 2.97949 4.42578C2.99121 4.42578 3 4.41699 3.00879 4.40234L4.53516 1.18555C4.54102 1.1709 4.54688 1.16211 4.54688 1.15039Z"
                                        fill="black"></path></svg></span></button>
                                    <div
                                        className={`_pairDropdown_14o74_24 scroll ${menuCoinIsShown ? 'active_14o74_24' : ''}`}
                                        bis_skin_checked="1">
                                        <ul>
                                            {coins.map(coin => (
                                                <li key={coin}>
                                                    <button
                                                        className={selectedCoin === coin ? "_success_1237m_51 _md_1237m_33 _button_1237m_1 _w100_1237m_22" : "_light_1237m_63 _md_1237m_33 _button_1237m_1 _w100_1237m_22"}
                                                        onClick={() => {toggleMenuCoin(); handleCoinSelection(coin) }}
                                                    >
                                                        USDT-{coin}
                                                    </button>
                                                </li> ))}
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="_controls_j0ym2_152" bis_skin_checked="1">
                            <div className="_title_j0ym2_102" bis_skin_checked="1">Direct Exchanges <div
                                className="_tooltip_1b29x_7" bis_skin_checked="1">
                                <button type="button" className="_link_1237m_75 _md_1237m_33 _button_1237m_1">
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M10 0H0V10H10V0ZM5.92788 8.5V4.43927H4.04808V8.5H5.92788ZM5.69712 2.18722C5.4984 2.06241 5.26442 2 4.99519 2C4.71955 2 4.48397 2.06241 4.28846 2.18722C4.09615 2.31204 4 2.55989 4 2.93077C4 3.29096 4.09615 3.53524 4.28846 3.66362C4.48397 3.78844 4.71955 3.85085 4.99519 3.85085C5.26442 3.85085 5.4984 3.78844 5.69712 3.66362C5.89904 3.53524 6 3.29096 6 2.93077C6 2.55989 5.89904 2.31204 5.69712 2.18722Z"
                                              fill="#2EB15A"></path>
                                    </svg>
                                </button>
                            </div></div>


                            {userDetails != null ? (
                                <div className="_controlsContainer_j0ym2_165" bis_skin_checked="1">
                                    <div className="_subscriptionBalance_j0ym2_201" bis_skin_checked="1">
                                        <div className="_text_j0ym2_205" bis_skin_checked="1">Balance</div>
                                        <div className="_balance_j0ym2_212" bis_skin_checked="1">
                                            <div className="_icon_j0ym2_178" bis_skin_checked="1">
                                                <img src={usdtTokenIcon} alt="Image for USDT"/>
                                            </div>
                                            <div className="_amount_j0ym2_225" bis_skin_checked="1">
                                                <span
                                                    class="_active_j0ym2_231">{userDetails.balance ? userDetails.balance : '0'}</span> USDT
                                            </div>
                                            <div className="_tooltip_j0ym2_241" bis_skin_checked="1">
                                                <div className="_tooltip_1b29x_7" bis_skin_checked="1">
                                                    <button type="button"
                                                            className="_link_1237m_75 _md_1237m_33 _button_1237m_1">
                                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                  d="M10 0H0V10H10V0ZM5.92788 8.5V4.43927H4.04808V8.5H5.92788ZM5.69712 2.18722C5.4984 2.06241 5.26442 2 4.99519 2C4.71955 2 4.48397 2.06241 4.28846 2.18722C4.09615 2.31204 4 2.55989 4 2.93077C4 3.29096 4.09615 3.53524 4.28846 3.66362C4.48397 3.78844 4.71955 3.85085 4.99519 3.85085C5.26442 3.85085 5.4984 3.78844 5.69712 3.66362C5.89904 3.53524 6 3.29096 6 2.93077C6 2.55989 5.89904 2.31204 5.69712 2.18722Z"
                                                                  fill="#2EB15A"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="_balanceControls_j0ym2_244" bis_skin_checked="1">
                                            <button onClick={showDepositModal} type="button"
                                                    className="_primary_1237m_41 _md_1237m_33 _button_1237m_1 _w100_1237m_22">Deposit
                                            </button>
                                            <button type="button"
                                                    className="_outline_1237m_69 _md_1237m_33 _button_1237m_1 _w100_1237m_22">Withdraw
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="_controlsContainer_j0ym2_165" bis_skin_checked="1">
                                    <div className="_subscriptionKey_j0ym2_174" bis_skin_checked="1">
                                        <div className="_icon_j0ym2_178" bis_skin_checked="1">
                                            <svg width="11" height="13" viewBox="0 0 11 13" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11 4.45293C11 3.97421 10.9059 3.51027 10.7216 3.07189C10.5428 2.64964 10.2873 2.27042 9.96186 1.945C9.63644 1.61957 9.25722 1.36407 8.83497 1.18522C8.39793 0.999647 7.93265 0.90686 7.45393 0.90686C6.9752 0.90686 6.51127 1.00099 6.07288 1.18522C5.65065 1.36407 5.27142 1.61957 4.94599 1.945C4.62056 2.27042 4.36506 2.64964 4.18621 3.07189C4.00064 3.50893 3.90785 3.97421 3.90785 4.45293C3.90785 5.05134 4.05846 5.63765 4.34489 6.15941L0.0995559 10.4007C0.00138999 10.4989 -0.029539 10.6481 0.0309742 10.7893C0.0430769 10.8189 0.0632479 10.8458 0.0861085 10.8687L1.03684 11.8194C1.0597 11.8423 1.08659 11.8611 1.11618 11.8745C1.25738 11.9351 1.40664 11.9055 1.50481 11.8059L2.09246 11.2183L2.9383 12.0641C2.96116 12.087 2.98805 12.1058 3.01762 12.1193C3.15882 12.1798 3.3081 12.1502 3.40625 12.0507L4.34353 11.1134C4.36639 11.0905 4.38522 11.0636 4.39867 11.0341C4.45918 10.8929 4.4296 10.7436 4.33009 10.6455L3.49769 9.81306L5.7461 7.56331C6.26651 7.84974 6.85416 8.00035 7.45257 8.00035C7.93129 8.00035 8.39523 7.90622 8.8336 7.72199C9.25585 7.54314 9.63508 7.28764 9.9605 6.96221C10.2859 6.6368 10.5414 6.25758 10.7203 5.83532C10.9059 5.39559 11 4.93166 11 4.45293ZM8.69648 5.69682C8.36433 6.02897 7.9219 6.21185 7.4526 6.21185C6.98328 6.21185 6.54085 6.02897 6.20871 5.69682C5.87656 5.36467 5.69233 4.92225 5.69233 4.45293C5.69233 3.98228 5.87522 3.5412 6.20737 3.20905C6.53952 2.8769 6.98195 2.69402 7.45125 2.69402C7.92056 2.69402 8.36298 2.8769 8.69513 3.20905C9.02728 3.5412 9.21017 3.98362 9.21017 4.45293C9.21284 4.92225 9.02996 5.36467 8.69648 5.69682Z"
                                                    fill="#B4B4B4"></path>
                                            </svg>
                                        </div>
                                        <div className="_input_j0ym2_184" bis_skin_checked="1">Please log in</div>
                                        <div className="_submitBtn_j0ym2_198" bis_skin_checked="1">
                                            <NavLink to="/us/login-user" style={{color:'white', textDecoration: 'none'}}>
                                                <button type="submit"
                                                        className="_primary_1237m_41 _md_1237m_33 _button_1237m_1 _w100_1237m_22">Log
                                                    in
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>

                            )}
                        </div>
                        <div className="_pair_j0ym2_38" bis_skin_checked="1">
                            <div className="_title_j0ym2_102" bis_skin_checked="1">Exchange Pairs</div>
                            <div className="_pairSelect_j0ym2_38" bis_skin_checked="1">
                                <div className="_pairLabel_j0ym2_116" bis_skin_checked="1"><span>{selectedCoin}-USDT</span></div>
                            </div>
                        </div>
                        <div className="_exchangeArrowsIcon_j0ym2_140" bis_skin_checked="1">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16 10.5062L11.2813 5.62476V8.16252H5.65649V12.8499H11.2813V15.3876L16 10.5062Z"
                                    fill="#B6B6B6"></path>
                                <path d="M10.3435 2.53776H4.71862V0L0 4.88144L4.71862 9.76287V7.22511H10.3435V2.53776Z"
                                      fill="#B6B6B6"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="_content_j0ym2_48" bis_skin_checked="1">
                    <div className="_standardForm_j0ym2_286" bis_skin_checked="1">
                        <form class="_standardForm_19ql4_1">
                            <div className="_formCol_19ql4_16" bis_skin_checked="1">
                                <div className="_formColItem_19ql4_33" bis_skin_checked="1">
                                    <div className="_label_19ql4_42" bis_skin_checked="1">
                                        <div className="_labelText_19ql4_46" bis_skin_checked="1">You Give<img
                                            src={usdtTokenIcon} alt="Img for USDT"/>USDT
                                        </div>
                                        <div className="_exchangeServiceInfo_19ql4_59" bis_skin_checked="1">
                                            <div className="_flag_19ql4_64" bis_skin_checked="1">

                                                <img
                                                    src={imagesflags[leftActiveItem.short + ".svg"]}
                                                    alt="Flag for VN"/>
                                            </div>
                                            <div className="_serviceData_19ql4_74" bis_skin_checked="1">
                                                <div className="_text_19ql4_77" bis_skin_checked="1">Purchase Price
                                                </div>
                                                <div className="_price_19ql4_82"
                                                     bis_skin_checked="1"> {leftActiveItem[selectedCoin]} USDT
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="_input_19ql4_88" bis_skin_checked="1">
                                        <div className="_noBorder_1wje8_60 _md_1wje8_78" bis_skin_checked="1"><label
                                            className="_label_1wje8_1"><span className="_labelText_1wje8_29"></span>
                                            <div className="_inputContainer_1wje8_44" bis_skin_checked="1"><input
                                                className="_input_1wje8_7 "
                                                placeholder="МIN 50 USDT/MAX 100000 USDT" onChange={handleInvestChange}
                                                type="number" autoComplete="off"
                                                value={startInvest}/></div>
                                            <span className="_inputTooltip_1wje8_38"><div className="_tooltip_1b29x_7"
                                                                                          bis_skin_checked="1"><button
                                                type="button" className="_link_1237m_75 _md_1237m_33 _button_1237m_1"><svg
                                                width="10" height="10" viewBox="0 0 10 10" fill="none"
                                                xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd"
                                                                                         clip-rule="evenodd"
                                                                                         d="M10 0H0V10H10V0ZM5.92788 8.5V4.43927H4.04808V8.5H5.92788ZM5.69712 2.18722C5.4984 2.06241 5.26442 2 4.99519 2C4.71955 2 4.48397 2.06241 4.28846 2.18722C4.09615 2.31204 4 2.55989 4 2.93077C4 3.29096 4.09615 3.53524 4.28846 3.66362C4.48397 3.78844 4.71955 3.85085 4.99519 3.85085C5.26442 3.85085 5.4984 3.78844 5.69712 3.66362C5.89904 3.53524 6 3.29096 6 2.93077C6 2.55989 5.89904 2.31204 5.69712 2.18722Z"
                                                                                         fill="#2EB15A"></path></svg></button></div></span></label>
                                        </div>
                                        <button type="button" onClick={setMax}
                                                className="_primary_1237m_41 _md_1237m_33 _button_1237m_1">MAX
                                        </button>
                                    </div>

                                </div>
                                <div className="_formColItem_19ql4_33" bis_skin_checked="1">
                                    <div className="_label_19ql4_42" bis_skin_checked="1">
                                        <div className="_labelText_19ql4_46" bis_skin_checked="1">You Get
                                            <img src={images[smallSelectedCoin + ".svg"]}
                                                 alt={"Img for " + selectedCoin}/>{selectedCoin}
                                        </div>
                                    </div>
                                    <div className="_input_19ql4_88" bis_skin_checked="1">
                                        <div className="_noBorder_1wje8_60 _md_1wje8_78" bis_skin_checked="1"><label
                                            class="_label_1wje8_1"><span class="_labelText_1wje8_29"></span>
                                            <div className="_inputContainer_1wje8_44" bis_skin_checked="1"><input
                                                class="_input_1wje8_7 " type="text" autocomplete="off" disabled
                                                value={youGive}/></div>
                                        </label></div>
                                    </div>
                                </div>
                            </div>
                            <div className="_formCol_19ql4_16" bis_skin_checked="1">
                                <div className="_formColItem_19ql4_33" bis_skin_checked="1">
                                    <div className="_label_19ql4_42" bis_skin_checked="1">
                                        <div className="_labelText_19ql4_46" bis_skin_checked="1">You Give
                                            <img src={images[smallSelectedCoin + ".svg"]}  alt={"Img for " + selectedCoin}/>{selectedCoin}
                                        </div>

                                        <div className="_exchangeServiceInfo_19ql4_59" bis_skin_checked="1">
                                            <div className="_flag_19ql4_64" bis_skin_checked="1"><img
                                                src={imagesflags[rightActiveItem.short + ".svg"]}
                                                alt="Flag for BR"/>
                                            </div>
                                            <div className="_serviceData_19ql4_74" bis_skin_checked="1">
                                                <div className="_text_19ql4_77" bis_skin_checked="1">Selling Price BTC
                                                </div>
                                                <div className="_price_19ql4_82" bis_skin_checked="1">{rightActiveItem[selectedCoin]}  USDT
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="_input_19ql4_88" bis_skin_checked="1">
                                        <div className="_noBorder_1wje8_60 _md_1wje8_78" bis_skin_checked="1"><label
                                            class="_label_1wje8_1"><span class="_labelText_1wje8_29"></span>
                                            <div className="_inputContainer_1wje8_44" bis_skin_checked="1">
                                                <input class="_input_1wje8_7 " type="text" autocomplete="off" disabled
                                                value={youGive}/></div>
                                            <span class="_inputTooltip_1wje8_38">
                                                <div className="_tooltip_1b29x_7" bis_skin_checked="1">
                                                <button
                                                type="button" className="_link_1237m_75 _md_1237m_33 _button_1237m_1">
                                                    <svg
                                                width="10" height="10" viewBox="0 0 10 10" fill="none"
                                                xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd"
                                                                                         clip-rule="evenodd"
                                                                                         d="M10 0H0V10H10V0ZM5.92788 8.5V4.43927H4.04808V8.5H5.92788ZM5.69712 2.18722C5.4984 2.06241 5.26442 2 4.99519 2C4.71955 2 4.48397 2.06241 4.28846 2.18722C4.09615 2.31204 4 2.55989 4 2.93077C4 3.29096 4.09615 3.53524 4.28846 3.66362C4.48397 3.78844 4.71955 3.85085 4.99519 3.85085C5.26442 3.85085 5.4984 3.78844 5.69712 3.66362C5.89904 3.53524 6 3.29096 6 2.93077C6 2.55989 5.89904 2.31204 5.69712 2.18722Z"
                                                                                         fill="#2EB15A"></path></svg></button></div>
                                            </span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="_formColItem_19ql4_33" bis_skin_checked="1">
                                    <div className="_label_19ql4_42" bis_skin_checked="1">
                                        <div className="_labelText_19ql4_46" bis_skin_checked="1">You Get<img
                                            src={usdtTokenIcon} alt="Img for USDT"/>USDT
                                        </div>
                                    </div>
                                    <div className="_input_19ql4_88" bis_skin_checked="1">
                                        <div className="_noBorder_1wje8_60 _md_1wje8_78" bis_skin_checked="1"><label
                                            class="_label_1wje8_1"><span class="_labelText_1wje8_29"></span>
                                            <div className="_inputContainer_1wje8_44" bis_skin_checked="1">
                                                <input
                                                class="_input_1wje8_7 " type="text" autocomplete="off" disabled
                                                value={youGet}/></div>
                                        </label></div>
                                    </div>
                                </div>
                            </div>
                            <div className="_formFooter_19ql4_119" bis_skin_checked="1">
                                <div className="_formFooterBtn_19ql4_129" bis_skin_checked="1">
                                    {userDetails != null ? (
                                        <button onClick={showDepositModal} type="button"
                                                className="_primary_1237m_41 _md_1237m_33 _button_1237m_1 _leftIcon_1237m_15 _w100_1237m_22 _svgColor_1237m_12">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M16 10.5062L11.2813 5.62476V8.16252H5.65649V12.8499H11.2813V15.3876L16 10.5062Z"
                                                    fill="#B6B6B6"></path>
                                                <path
                                                    d="M10.3435 2.53776H4.71862V0L0 4.88144L4.71862 9.76287V7.22511H10.3435V2.53776Z"
                                                    fill="#B6B6B6"></path>
                                            </svg>

                                            Start
                                                Exchange
                                        </button> ) : (

                                        <button type="button"
                                                className="_primary_1237m_41 _md_1237m_33 _button_1237m_1 _leftIcon_1237m_15 _w100_1237m_22 _svgColor_1237m_12">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M16 10.5062L11.2813 5.62476V8.16252H5.65649V12.8499H11.2813V15.3876L16 10.5062Z"
                                                    fill="#B6B6B6"></path>
                                                <path
                                                    d="M10.3435 2.53776H4.71862V0L0 4.88144L4.71862 9.76287V7.22511H10.3435V2.53776Z"
                                                    fill="#B6B6B6"></path>
                                            </svg>
                                            <NavLink to='/us/login-user'
                                                     style={{color: 'white', textDecoration: 'none'}}> Start
                                                Exchange</NavLink>
                                        </button>

                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="_mobileForm_j0ym2_291" bis_skin_checked="1">
                        <form class="_mobileForm_p1aww_1">
                            <div className="_profit_p1aww_47 _transit_p1aww_61" bis_skin_checked="1">Expected
                                Profit<span>{roundToTwoDecimalPlaces(profit)} USDT</span><img
                                    src={usdtTokenIcon} alt="Img for USDT"/></div>
                            <div className="_exchangeInfo_p1aww_64" bis_skin_checked="1">
                                <div className="_infoItem_p1aww_69" bis_skin_checked="1">
                                    <div className="_itemHeader_p1aww_72" bis_skin_checked="1">
                                        <div className="_itemTitle_p1aww_77" bis_skin_checked="1">Purchase USDT/BTC
                                        </div>
                                        <div className="_exchangeServiceInfo_p1aww_82" bis_skin_checked="1">
                                            <div className="_flag_p1aww_85" bis_skin_checked="1"><img
                                                src="/flags/vn.svg"
                                                alt="Flag for VN"/>
                                            </div>
                                            <div className="_price_p1aww_95" bis_skin_checked="1">57,926.39 USDT</div>
                                        </div>
                                    </div>
                                    <div className="_itemContent_p1aww_101" bis_skin_checked="1">
                                        <div className="_itemCol_p1aww_119" bis_skin_checked="1">
                                            <div className="_tokenIcon_p1aww_123" bis_skin_checked="1"><img
                                                src={usdtTokenIcon} alt="Img for USDT"/></div>
                                            <div className="_value_p1aww_28 _error_p1aww_131" bis_skin_checked="1">1000
                                            </div>
                                        </div>
                                        <div className="_itemCol_p1aww_119" bis_skin_checked="1">
                                            <div className="_value_p1aww_28" bis_skin_checked="1">0.017263038</div>
                                            <div className="_tokenIcon_p1aww_123" bis_skin_checked="1"><img
                                                src={btcTokenIcon} alt="Img for BTC"/></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="_infoItem_p1aww_69" bis_skin_checked="1">
                                    <div className="_itemHeader_p1aww_72" bis_skin_checked="1">
                                        <div className="_itemTitle_p1aww_77" bis_skin_checked="1">Sell BTC/USDT</div>
                                        <div className="_exchangeServiceInfo_p1aww_82" bis_skin_checked="1">
                                            <div className="_flag_p1aww_85" bis_skin_checked="1"><img
                                                src="/flags/br.svg"
                                                alt="Flag for BR"/>
                                            </div>
                                            <div className="_price_p1aww_95" bis_skin_checked="1">57,958.85 USDT</div>
                                        </div>
                                    </div>
                                    <div className="_itemContent_p1aww_101" bis_skin_checked="1">
                                        <div className="_itemCol_p1aww_119" bis_skin_checked="1">
                                            <div className="_tokenIcon_p1aww_123" bis_skin_checked="1"><img
                                                src={btcTokenIcon} alt="Img for BTC"/></div>
                                            <div className="_value_p1aww_28" bis_skin_checked="1">0.017263038</div>
                                        </div>
                                        <div className="_itemCol_p1aww_119" bis_skin_checked="1">
                                            <div className="_value_p1aww_28" bis_skin_checked="1">1,000.530618154</div>
                                            <div className="_tokenIcon_p1aww_123" bis_skin_checked="1"><img
                                                src={usdtTokenIcon} alt="Img for USDT"/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="_formFooterBtn_p1aww_134" bis_skin_checked="1">
                                {userDetails != null ? (
                                    <button onClick={showDepositModal} type="button"
                                            className="_primary_1237m_41 _md_1237m_33 _button_1237m_1 _leftIcon_1237m_15 _w100_1237m_22 _svgColor_1237m_12">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M16 10.5062L11.2813 5.62476V8.16252H5.65649V12.8499H11.2813V15.3876L16 10.5062Z"
                                                fill="#B6B6B6"></path>
                                            <path
                                                d="M10.3435 2.53776H4.71862V0L0 4.88144L4.71862 9.76287V7.22511H10.3435V2.53776Z"
                                                fill="#B6B6B6"></path>
                                        </svg>

                                        Start
                                        Exchange
                                    </button> ) : (

                                    <button type="button"
                                            className="_primary_1237m_41 _md_1237m_33 _button_1237m_1 _leftIcon_1237m_15 _w100_1237m_22 _svgColor_1237m_12">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M16 10.5062L11.2813 5.62476V8.16252H5.65649V12.8499H11.2813V15.3876L16 10.5062Z"
                                                fill="#B6B6B6"></path>
                                            <path
                                                d="M10.3435 2.53776H4.71862V0L0 4.88144L4.71862 9.76287V7.22511H10.3435V2.53776Z"
                                                fill="#B6B6B6"></path>
                                        </svg>
                                        <NavLink to='/us/login-user'
                                                 style={{color: 'white', textDecoration: 'none'}}> Start
                                            Exchange</NavLink>
                                    </button>

                                )}
                            </div>
                        </form>
                    </div>
                    <div className="_exchangeDataCheck_j0ym2_295" bis_skin_checked="1">
                        <div className="_wrapper_9xi02_1" bis_skin_checked="1">
                            <div className="_title_9xi02_15" bis_skin_checked="1">Exchange Data Verification</div>
                            <div className="_content_9xi02_22" bis_skin_checked="1">
                                <div className="_order_9xi02_45" bis_skin_checked="1">
                                    <div className="_tit_9xi02_15" bis_skin_checked="1">Purchase Order ID</div>
                                    <div className="_id_9xi02_45" bis_skin_checked="1">№ not found</div>
                                </div>
                                <div className="_profit_9xi02_57" bis_skin_checked="1">
                                    <div className="_tit_9xi02_15" bis_skin_checked="1">Expected Profit</div>
                                    <div className="_value_9xi02_61" bis_skin_checked="1">{roundToTwoDecimalPlaces(profit)}USDT<img
                                        src={usdtTokenIcon} alt="Image for USDT"/></div>
                                </div>
                                <div className="_order_9xi02_45" bis_skin_checked="1">
                                    <div className="_tit_9xi02_15" bis_skin_checked="1">Sale Order ID</div>
                                    <div className="_id_9xi02_45" bis_skin_checked="1">№ not found</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="_exchangeBroadcast_j0ym2_303" bis_skin_checked="1">
                        <div className="_title_1pfus_1" bis_skin_checked="1">Online Exchange Broadcast</div>
                        <div className="_wrapper_1pfus_14" bis_skin_checked="1">
                            <div className="_item_1pfus_19 _noOrder_1pfus_107" bis_skin_checked="1">
                                <div className="_content_1pfus_41" bis_skin_checked="1">
                                    <div className="_name_1pfus_41" bis_skin_checked="1">Transaction Payment</div>
                                    <div className="_value_1pfus_51" bis_skin_checked="1">
                                        <div className="_statusText_1pfus_92" bis_skin_checked="1">Not Found</div>
                                    </div>
                                </div>
                                <div className="_status_1pfus_48" bis_skin_checked="1">
                                    <div className="_icon_1pfus_87" bis_skin_checked="1"></div>
                                    <div className="_statusText_1pfus_92" bis_skin_checked="1"></div>
                                </div>
                            </div>
                            <div className="_item_1pfus_19 _noOrder_1pfus_107" bis_skin_checked="1">
                                <div className="_content_1pfus_41" bis_skin_checked="1">
                                    <div className="_name_1pfus_41" bis_skin_checked="1">Exchange</div>
                                    <div className="_value_1pfus_51" bis_skin_checked="1">
                                        <div className="_statusText_1pfus_92" bis_skin_checked="1">No pair selected
                                        </div>
                                    </div>
                                </div>
                                <div className="_status_1pfus_48" bis_skin_checked="1">
                                    <div className="_icon_1pfus_87" bis_skin_checked="1"></div>
                                    <div className="_statusText_1pfus_92" bis_skin_checked="1"></div>
                                </div>
                            </div>
                            <div className="_item_1pfus_19 _noOrder_1pfus_107" bis_skin_checked="1">
                                <div className="_content_1pfus_41" bis_skin_checked="1">
                                    <div className="_name_1pfus_41" bis_skin_checked="1">Transfer</div>
                                    <div className="_value_1pfus_51" bis_skin_checked="1">
                                        <div className="_statusText_1pfus_92" bis_skin_checked="1">No markets selected
                                        </div>
                                    </div>
                                </div>
                                <div className="_status_1pfus_48" bis_skin_checked="1">
                                    <div className="_icon_1pfus_87" bis_skin_checked="1"></div>
                                    <div className="_statusText_1pfus_92" bis_skin_checked="1"></div>
                                </div>
                            </div>
                            <div className="_item_1pfus_19 _noOrder_1pfus_107" bis_skin_checked="1">
                                <div className="_content_1pfus_41" bis_skin_checked="1">
                                    <div className="_name_1pfus_41" bis_skin_checked="1">Exchange</div>
                                    <div className="_value_1pfus_51" bis_skin_checked="1">
                                        <div className="_statusText_1pfus_92" bis_skin_checked="1">No pair selected
                                        </div>
                                    </div>
                                </div>
                                <div className="_status_1pfus_48" bis_skin_checked="1">
                                    <div className="_icon_1pfus_87" bis_skin_checked="1"></div>
                                    <div className="_statusText_1pfus_92" bis_skin_checked="1"></div>
                                </div>
                            </div>
                            <div className="_item_1pfus_19 _noOrder_1pfus_107" bis_skin_checked="1">
                                <div className="_content_1pfus_41" bis_skin_checked="1">
                                    <div className="_name_1pfus_41" bis_skin_checked="1">Result</div>
                                    <div className="_value_1pfus_51" bis_skin_checked="1">
                                        <div className="_statusText_1pfus_92" bis_skin_checked="1">Hash not found</div>
                                    </div>
                                </div>
                                <div className="_status_1pfus_48" bis_skin_checked="1">
                                    <div className="_icon_1pfus_87" bis_skin_checked="1"></div>
                                    <div className="_statusText_1pfus_92" bis_skin_checked="1"></div>
                                </div>
                            </div>
                            <div className="_item_1pfus_19 _historyBtn_1pfus_116" bis_skin_checked="1"><a class=""
                                                                                                          href="/us/exchanges-history/transit">
                                <svg width="23" height="25" viewBox="0 0 23 25" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.1959 1.83041V0.777893L7.6747 3.33611L11.1959 5.89428V4.84176C15.7088 4.84176 19.3804 8.51331 19.3804 13.0263C19.3804 17.5392 15.7088 21.2108 11.1959 21.2108C6.68289 21.2108 3.01135 17.5392 3.01135 13.0263H0C0 19.2096 5.01258 24.2221 11.1959 24.2221C17.3792 24.2221 22.3917 19.2096 22.3917 13.0263C22.3917 6.84299 17.3792 1.83041 11.1959 1.83041Z"
                                        fill="#2EB15A"></path>
                                    <path
                                        d="M15.5897 13.0263C15.5897 10.6035 13.6187 8.63251 11.1959 8.63251C8.77317 8.63251 6.80215 10.6036 6.80215 13.0263C6.80215 15.449 8.77321 17.4201 11.1959 17.4201C13.6187 17.4201 15.5897 15.449 15.5897 13.0263ZM10.509 11.2052H11.8827V13.0751L12.8808 13.8745L12.022 14.9467L10.509 13.7349V11.2052Z"
                                        fill="#2EB15A"></path>
                                </svg>
                                Exchange Operation History</a></div>
                        </div>
                    </div>
                </div>
                <AsideRight  items={rightItems}
                             selectedCoin={selectedCoin}
                             activeItem={rightActiveItem}
                             onItemClick={handleRightItemClick}   />

            </div>
            {userDetails ? (
                    <div className={`_modal_jokfe_1 ${depositModal ? 'active_jokfe_1' : ''}`}   >
                        <div className="_content_jokfe_18" bis_skin_checked="1">
                            <button onClick={hideDepositModal} className="_close_jokfe_52" type="button">
                                <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.879883 6.60449C0.708984 6.43359 0.713867 6.15039 0.879883 5.98438L3.38477 3.47949L0.879883 0.979492C0.713867 0.813477 0.708984 0.525391 0.879883 0.359375C1.0459 0.188477 1.33398 0.193359 1.5 0.359375L4 2.86426L6.50488 0.359375C6.6709 0.193359 6.9541 0.188477 7.12012 0.359375C7.2959 0.525391 7.29102 0.813477 7.12012 0.979492L4.62012 3.47949L7.12012 5.98438C7.29102 6.15039 7.2959 6.43359 7.12012 6.60449C6.9541 6.77539 6.6709 6.77051 6.50488 6.60449L4 4.09961L1.5 6.60449C1.33398 6.77051 1.0459 6.77539 0.879883 6.60449Z"
                                        fill="#7F7F7F"></path>
                                </svg>
                            </button>
                            <div className="_wrapper_w8dt0_1" bis_skin_checked="1">
                                <div className="_title_w8dt0_4" bis_skin_checked="1"><span>Sending</span>
                                    <button type="button" className="_link_1237m_75 _md_1237m_33 _button_1237m_1">History
                                    </button>
                                    <button type="button" className="_link_1237m_75 _md_1237m_33 _button_1237m_1">Payment
                                        Information
                                    </button>
                                </div>
                                <div className="_text_w8dt0_21" bis_skin_checked="1">Send the asset (at least 50 USDT) to the
                                    specified exchange service address
                                </div>
                                <div className="_tabsBlock_w8dt0_85" bis_skin_checked="1">
                                    <button onClick={() => handleSectionClick('matic')} className={`${activeSection === 'matic' ? '_active_w8dt0_104' : ''}`} >USDT Polygon (MATIC)</button>
                                    <button onClick={() => handleSectionClick('trc')} className={`${activeSection === 'trc' ? '_active_w8dt0_104' : ''}`} >USDT TRC20 (Tron)</button>
                                    <button onClick={() => handleSectionClick('erc')} className={`${activeSection === 'erc' ? '_active_w8dt0_104' : ''}`} >USDT ERC20 (Etherium)</button>
                                </div>

                                <div className="_copyBlock_w8dt0_25" bis_skin_checked="1">
                                    <div className="_network_w8dt0_36" bis_skin_checked="1">{activeSection === 'matic' ? 'USDT Polygon' : ''}{activeSection === 'trc' ? 'USDT Trc20' : ''}{activeSection === 'erc' ? 'USDT Erc20' : ''}</div>
                                    <div className="_address_w8dt0_44" bis_skin_checked="1">

                                        {activeSection === 'matic' ? shortenWallet(userDetails.dep_wallet_poligon) : ''}{activeSection === 'trc' ? shortenWallet(userDetails.dep_wallet_trc) : ''}{activeSection === 'erc' ? shortenWallet(userDetails.dep_wallet_poligon) : ''}
                                    </div>
                                    <button onClick={copyToClipboard} className="_copyBtn_w8dt0_50" type="button">
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1.41699 7.38037C0.555664 7.38037 0.120605 6.94531 0.120605 6.09717V1.65869C0.120605 0.806152 0.555664 0.375488 1.41699 0.375488H5.8291C6.68604 0.375488 7.12549 0.814941 7.12549 1.65869V2.2915H7.96045C8.81738 2.2915 9.25684 2.72656 9.25684 3.57471V8.00879C9.25684 8.85693 8.81738 9.29639 7.96045 9.29639H3.54834C2.68701 9.29639 2.25195 8.86133 2.25195 8.00879V7.38037H1.41699ZM1.42578 6.83545H2.25195V3.57471C2.25195 2.72217 2.68701 2.2915 3.54834 2.2915H6.58057V1.68945C6.58057 1.17969 6.30371 0.92041 5.82031 0.92041H1.42578C0.933594 0.92041 0.665527 1.17969 0.665527 1.68945V6.06641C0.665527 6.57617 0.933594 6.83545 1.42578 6.83545ZM3.55713 8.75146H7.95166C8.43506 8.75146 8.71191 8.48779 8.71191 7.98242V3.60107C8.71191 3.0957 8.43506 2.83203 7.95166 2.83203H3.55713C3.06494 2.83203 2.79688 3.0957 2.79688 3.60107V7.98242C2.79688 8.48779 3.06494 8.75146 3.55713 8.75146Z"
                                                fill="black"></path>
                                        </svg>
                                    </button>
                                </div>
                                <div className="_qrCode_w8dt0_57" bis_skin_checked="1">
                                    <div id="wallettocopy"
                                         style={{display: 'none'}}>{activeSection === 'matic' ? userDetails.dep_wallet_poligon : ''}{activeSection === 'trc' ? userDetails.dep_wallet_trc : ''}{activeSection === 'erc' ? userDetails.dep_wallet_poligon : ''}</div>
                                    {activeSection === 'matic' ? (
                                        <QRCodeCanvas value={userDetails.dep_wallet_poligon}
                                                      style={{border: '2px solid #fff'}} alt="qr code url"
                                                      size={120}/> ) : ''}{activeSection === 'trc' ?
                                    <QRCodeCanvas value={userDetails.dep_wallet_trc} style={{border: '2px solid #fff'}}
                                                  alt="qr code url" size={120}/> : ''}{activeSection === 'erc' ? (
                                    <QRCodeCanvas value={userDetails.dep_wallet_poligon}
                                                  style={{border: '2px solid #fff'}} alt="qr code url"
                                                  size={120}/> ) : ''}

                                </div>
                            </div>
                        </div>
                    </div> ) : (
                <>
                </> )}
        </div>

    );
};

export default DirectExchangesPage;
