import React from 'react';

import logomain from '../img/landing/logoMain.png';


const HomePageBlockLastTransaction = () => {


    return (

        <div bis_skin_checked="1">
            <div className="_onlineOperationWrapper_njvc2_456" bis_skin_checked="1">
                <div className="_onlineOperationContent_njvc2_467" bis_skin_checked="1">
                    <div bis_skin_checked="1"><h2 className="_idOperationTitle_njvc2_493">Purchase Order ID</h2><p
                        className="_idOperation_njvc2_493"><a
                        href="https://sdbs-cl.com/exchange/expi/request/4ed761b5-5d13-44ee-87b8-0980ca18a084"
                        target="_blank">№ 4ed761b5-5d13-44ee-87b8-0980ca18a084</a></p></div>
                    <div className="_middleOperationContent_njvc2_539" bis_skin_checked="1"><h2
                        className="_exchangeTitleTop_njvc2_550">Current Exchange</h2>
                        <div className="_middleSummUsdt_njvc2_573" bis_skin_checked="1"><p>22554.2501 USDT</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"
                                 fill="none">
                                <g clip-path="url(#clip0_43_1747)">
                                    <rect x="0.462891" y="0.658813" width="19.9899" height="20.0147" rx="9"
                                          fill="#F2F2F2"></rect>
                                    <path
                                        d="M0.462891 10.6662C0.462891 16.1862 4.93777 20.6735 10.4578 20.6735C15.9779 20.6735 20.4527 16.1862 20.4527 10.6662C20.4527 5.14612 15.9779 0.658813 10.4578 0.658813C4.93777 0.658813 0.462891 5.14612 0.462891 10.6662Z"
                                        fill="#6AB784"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M6.03153 5.66248H14.8447V7.69022H11.5019V9.03189C14.1081 9.1364 16.08 9.6252 16.08 10.2124C16.08 10.7998 14.1077 11.2886 11.5014 11.3931V15.6698H9.37395V11.3914C6.78752 11.2835 4.83569 10.7967 4.83569 10.2124C4.83569 9.6282 6.78777 9.14141 9.37427 9.03345V7.69022H6.03153V5.66248ZM9.37427 9.14741C7.1299 9.23954 5.45081 9.61188 5.45081 10.0571C5.45081 10.5717 7.69268 10.9888 10.4579 10.9888C13.2231 10.9888 15.4649 10.5717 15.4649 10.0571C15.4649 9.60931 13.766 9.23516 11.5019 9.14579V10.6705H9.37427V9.14741Z"
                                          fill="white"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_43_1747">
                                        <rect x="0.462891" y="0.658813" width="19.9899" height="20.0147" rx="9"
                                              fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <p className="_topProfitTitle_njvc2_622">Expected Profit</p>
                        <div className="_totalProfit_njvc2_387" bis_skin_checked="1"><p>+ 6.71 USDT</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"
                                 fill="none">
                                <g clip-path="url(#clip0_43_1730)">
                                    <path
                                        d="M0.563232 12.6412C0.563232 19.5412 6.15684 25.1503 13.0569 25.1503C19.957 25.1503 25.5506 19.5412 25.5506 12.6412C25.5506 5.74109 19.957 0.131958 13.0569 0.131958C6.15684 0.131958 0.563232 5.74109 0.563232 12.6412Z"
                                        fill="#6AB784"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M7.52409 6.3866H18.5405V8.92127H14.3621V10.5984C17.6197 10.729 20.0847 11.34 20.0847 12.0741C20.0847 12.8082 17.6194 13.4193 14.3615 13.5498V18.8958H11.7021V13.5477C8.46907 13.4129 6.0293 12.8044 6.0293 12.0741C6.0293 11.3438 8.46939 10.7353 11.7025 10.6003V8.92127H7.52409V6.3866ZM11.7025 10.7428C8.89706 10.8579 6.7982 11.3234 6.7982 11.8799C6.7982 12.5231 9.60053 13.0445 13.0571 13.0445C16.5136 13.0445 19.3158 12.5231 19.3158 11.8799C19.3158 11.3201 17.1922 10.8525 14.3621 10.7407V12.6467H11.7025V10.7428Z"
                                          fill="white"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_43_1730">
                                        <rect x="0.563232" y="0.131958" width="24.9873" height="25.0184" rx="9"
                                              fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div bis_skin_checked="1"><h2 className="_idOperationTitle_njvc2_493">Sale Order ID</h2><p
                        className="_idOperation_njvc2_493"><a
                        href="https://sdbs-br.com/exchange/expi/request/11fd2277-a9af-4fba-9526-98625714fa97"
                        target="_blank">№ 11fd2277-a9af-4fba-9526-98625714fa97</a></p></div>
                </div>
            </div>
        </div>


    );
};

export default HomePageBlockLastTransaction;
