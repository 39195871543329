import React, {useState} from 'react';
import {NavLink} from "react-router-dom";

import {toast} from "react-toastify";
import {QRCodeCanvas} from "qrcode.react";



const SubscriptionPage = ({userDetails}) => {
    const [activeSection, setActiveSection] = useState('plus');
    const [exampleIsShown, setExampleIsShown] = useState(false);
    const [buySubscribeIsShown, setBuySubscribeIsShown] = useState(false);
    const handleSectionClick = (section) => setActiveSection(section);

    const calculateExample = () => {
        setExampleIsShown(true);
    };
    const buysubscribe = () => {
        setBuySubscribeIsShown(true);
    };
    const calculateExamplehide = () => {
        setExampleIsShown(false);
    };
    const buysubscribehide = () => {
        setBuySubscribeIsShown(false);
    };
    const shortenWallet = (wallet) => {
        if (!wallet) return '';
        return `${wallet.substring(0, 4)}...${wallet.substring(wallet.length - 4)}`;
    };
    const copyToClipboard = () => {
        const wallet = document.getElementById('wallettocopy');
        navigator.clipboard.writeText(wallet.textContent).then(() => {

            toast.success(wallet.textContent + ' - Address successfully copied', {
                position: 'top-right',
                autoClose: 3000
            });

        }).catch(err => {

            console.error('Failed to copy: ', err);
        });
    };

    return (

            <main>
                <div className="_container_4214v_15" bis_skin_checked="1">
                    <div className="_container_1s3p5_1" bis_skin_checked="1">
                        <div className="_content_1s3p5_33" bis_skin_checked="1">
                            <aside className="_aside_1s3p5_40">
                                <div className="_mainBlock_1rnu1_63" bis_skin_checked="1">
                                    <h2><span>Subscription Indicator</span> </h2>
                                    <div className="_borderBlock_1rnu1_85" bis_skin_checked="1">
                                        <div className="_topBlock_1rnu1_91" bis_skin_checked="1">
                                            <div className="_tabs_1rnu1_111" bis_skin_checked="1">
                                                <button onClick={() => handleSectionClick('free')}
                                                        className={`${activeSection === 'free' ? '_active_1rnu1_126' : ''}`}>Expi
                                                    Free
                                                </button>
                                                <button onClick={() => handleSectionClick('plus')}
                                                        className={`${activeSection === 'plus' ? '_active_1rnu1_126' : ''}`}>Expi
                                                    +
                                                </button>
                                            </div>
                                            {userDetails ? (
                                                <>
                                                    <h3>Subscription Status: <b>FREE</b></h3>
                                                    <p>Free/Unlimited</p>
                                                    {activeSection === 'plus' ? (
                                                        <div className="_plusButtons_1rnu1_135" bis_skin_checked="1">
                                                            <button onClick={buysubscribe}
                                                                className="_primary_1237m_41 _md_1237m_33 _button_1237m_1 _w100_1237m_22">Buy
                                                            </button>
                                                        </div> ) : ''}
                                                </> ) : (

                                                <NavLink to="/us/login-user">
                                                    <div className="_plusButtons_1rnu1_135" bis_skin_checked="1">

                                                        <button
                                                            className="_primary_1237m_41 _md_1237m_33 _button_1237m_1 _w100_1237m_22">Log
                                                            in
                                                        </button>

                                                    </div>
                                                </NavLink> )}
                                        </div>
                                        {activeSection === 'free' ? (
                                            <div className="_textBlock_1rnu1_30" bis_skin_checked="1">
                                                <h4>EXPI Free
                                                    Subscription</h4><h4>Free and unlimited </h4>
                                                <ul>
                                                    <li>Limits for exchanges from 50 USDT to 100 000 USDT</li>
                                                    <li>Extensive selection of top crypto-pairs for simultaneous
                                                        exchanges/profit from 33.7% per month
                                                    </li>
                                                    <li>EXPI.bot collection from the income of each client exchange
                                                        operation is 21%
                                                    </li>
                                                    <li>Simultaneous access to 56 crypto-exchange services and 567
                                                        markets
                                                    </li>
                                                    <li>Direct and automatic exchanges in manual and automatic mode</li>
                                                    <li>Independent selection of the best offers for exchange</li>
                                                    <li>Automatic selection of the best offers for exchange</li>
                                                    <li> The interval between exchange operations will be only up to 10
                                                        minutes
                                                    </li>
                                                    <li>Open broadcast of exchange operations online</li>
                                                    <li>Open access to the partnership and partner account</li>
                                                </ul>
                                            </div> ) : ''}
                                        {activeSection === 'plus' ? (
                                            <div className="_textBlock_1rnu1_30" bis_skin_checked="1"><h4>Expi +</h4>
                                                <h4>Premium subscription</h4>
                                                <ul>
                                                    <li>Limits for exchanges from 50 USDT to 100,000 USDT</li>
                                                    <li>Extensive choice of top crypto pairs for simultaneous
                                                        exchanges/profit of 33.7% per month
                                                    </li>
                                                    <li>Simultaneous access to 56 crypto exchange services and 567
                                                        markets
                                                    </li>
                                                    <li>Direct and automatic exchanges in manual and automatic mode</li>
                                                    <li>Independent choice of the best offers for exchange</li>
                                                    <li>Automatic selection of the best offers for exchange</li>
                                                    <li>The interval between exchange operations is only up to 10
                                                        minutes
                                                    </li>
                                                    <li>Open broadcast of exchange operations in online mode</li>
                                                    <li>Open access to partnership and partner cabinet</li>
                                                </ul>
                                            </div> ) : ''}
                                        <div className="_refBlock_1rnu1_130" bis_skin_checked="1"></div>
                                        <div className="_primerPopupOpenBtnWrapper_1rnu1_152" bis_skin_checked="1">
                                            <button onClick={calculateExample}
                                                    className="_primary_1237m_41 _md_1237m_33 _button_1237m_1 _w100_1237m_22">Calculation
                                                example
                                            </button>
                                        </div>
                                    </div>
                                    <div className="_modal_jokfe_1" style={{display: exampleIsShown ? 'flex' : ''}}>
                                        <div className="_content_jokfe_18 _contentLg_jokfe_44" bis_skin_checked="1">
                                            <button onClick={calculateExamplehide} className="_close_jokfe_52"
                                                    type="button">
                                                <svg width="8" height="7" viewBox="0 0 8 7" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M0.879883 6.60449C0.708984 6.43359 0.713867 6.15039 0.879883 5.98438L3.38477 3.47949L0.879883 0.979492C0.713867 0.813477 0.708984 0.525391 0.879883 0.359375C1.0459 0.188477 1.33398 0.193359 1.5 0.359375L4 2.86426L6.50488 0.359375C6.6709 0.193359 6.9541 0.188477 7.12012 0.359375C7.2959 0.525391 7.29102 0.813477 7.12012 0.979492L4.62012 3.47949L7.12012 5.98438C7.29102 6.15039 7.2959 6.43359 7.12012 6.60449C6.9541 6.77539 6.6709 6.77051 6.50488 6.60449L4 4.09961L1.5 6.60449C1.33398 6.77051 1.0459 6.77539 0.879883 6.60449Z"
                                                        fill="#7F7F7F"></path>
                                                </svg>
                                            </button>
                                            <div className="_exampleModal_1rnu1_157" bis_skin_checked="1">
                                                <h2>Calculation example</h2>
                                                <div bis_skin_checked="1">Here we will give an example of calculation
                                                    under the EXPI Free and EXPI+ subscription terms with different
                                                    amounts.
                                                </div>
                                                <div bis_skin_checked="1">You will see which option is the most
                                                    profitable for you, taking into account your exchanged volume.
                                                </div>
                                                <h3>First option.</h3><p>If you are working with <b>1000 USDT</b>.</p>
                                                <p>Take into account the period: one month .</p><p>Access to all top
                                                crypto pairs for exchange is open.</p><p>The average return is ~ 40% per
                                                month</p><p>We receive: EXPI Free subscription, income 400 USDT – EXPI
                                                fee 21% = 316 USDT.</p><p>We get: EXPI +, income 400 – subscription cost
                                                284 USDT = 116 USDT.</p><p><span>Conclusion</span>: in In this case,
                                                with a volume of 1000 USDT or less, it is profitable for the client to
                                                work with a free subscription, then he will pay the EXPI fee and make
                                                more profit than working with a paid subscription.</p><h3>Second
                                                option.</h3><p>If you work with 5000 USDT on the same conditions as in
                                                the first calculation.</p><p>We get: EXPI Free, income 2000 – EXPI fee
                                                21% = 1580 USDT.</p><p>We get : EXPI +, income 2000 – subscription cost
                                                284 USDT = 1716 USDT.</p><p><span>Conclusion</span>: in this case, we
                                                can conclude that with a volume of up to 4000 USDT or less, It is more
                                                profitable for the client to work with a free subscription and pay the
                                                EXPI fee. With volumes of 4,000 USDT or more, it will be more profitable
                                                to work with a paid subscription.</p><h3>Third option.</h3><p>If you
                                                work with 20,000 USDT on the same conditions as in the calculations
                                                above. </p><p>We get: EXPI Free, income 8000 USDT – EXPI fee 21% = 6320
                                                USDT.</p><p>We get: EXPI +, income 8000 USDT – subscription cost 284
                                                USDT = 7716 USDT.</p><p>We get: EXPI +, income 8000 USDT – subscription
                                                cost 284 USDT = 7716 USDT.</p><p><span>Conclusion</span>: here we see
                                                that for a client with volumes of 10,000 USDT, 20,000 USDT or more, it
                                                will be profitable to work with a paid subscription.</p>
                                                <div bis_skin_checked="1">EXPI Team provided a simple calculation for
                                                    clients to make it easier for them to navigate what type of payment
                                                    for EXPI services to work with.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="_modal_jokfe_1" style={{display: buySubscribeIsShown ? 'flex' : ''}}>
                                        <div className="_content_jokfe_18" bis_skin_checked="1">
                                            <button onClick={buysubscribehide} className="_close_jokfe_52" type="button">
                                                <svg width="8" height="7" viewBox="0 0 8 7" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M0.879883 6.60449C0.708984 6.43359 0.713867 6.15039 0.879883 5.98438L3.38477 3.47949L0.879883 0.979492C0.713867 0.813477 0.708984 0.525391 0.879883 0.359375C1.0459 0.188477 1.33398 0.193359 1.5 0.359375L4 2.86426L6.50488 0.359375C6.6709 0.193359 6.9541 0.188477 7.12012 0.359375C7.2959 0.525391 7.29102 0.813477 7.12012 0.979492L4.62012 3.47949L7.12012 5.98438C7.29102 6.15039 7.2959 6.43359 7.12012 6.60449C6.9541 6.77539 6.6709 6.77051 6.50488 6.60449L4 4.09961L1.5 6.60449C1.33398 6.77051 1.0459 6.77539 0.879883 6.60449Z"
                                                        fill="#7F7F7F"></path>
                                                </svg>
                                            </button>
                                            <div className="_wrapper_2cae9_1" bis_skin_checked="1">
                                                <div className="_title_2cae9_5" bis_skin_checked="1">Subscription
                                                    Payment
                                                </div>
                                                <div className="_price_2cae9_11" bis_skin_checked="1">Amount <b>71
                                                    USDT</b> Polygon
                                                </div>
                                                <div className="_copyBlock_2cae9_16" bis_skin_checked="1">
                                                    <div className="_address_2cae9_25"
                                                         bis_skin_checked="1">{userDetails ? (shortenWallet(userDetails.dep_wallet_poligon)) : ''}
                                                    </div>
                                                    <button onClick={copyToClipboard} className="_copyBtn_2cae9_30" type="button">
                                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M1.41699 7.38037C0.555664 7.38037 0.120605 6.94531 0.120605 6.09717V1.65869C0.120605 0.806152 0.555664 0.375488 1.41699 0.375488H5.8291C6.68604 0.375488 7.12549 0.814941 7.12549 1.65869V2.2915H7.96045C8.81738 2.2915 9.25684 2.72656 9.25684 3.57471V8.00879C9.25684 8.85693 8.81738 9.29639 7.96045 9.29639H3.54834C2.68701 9.29639 2.25195 8.86133 2.25195 8.00879V7.38037H1.41699ZM1.42578 6.83545H2.25195V3.57471C2.25195 2.72217 2.68701 2.2915 3.54834 2.2915H6.58057V1.68945C6.58057 1.17969 6.30371 0.92041 5.82031 0.92041H1.42578C0.933594 0.92041 0.665527 1.17969 0.665527 1.68945V6.06641C0.665527 6.57617 0.933594 6.83545 1.42578 6.83545ZM3.55713 8.75146H7.95166C8.43506 8.75146 8.71191 8.48779 8.71191 7.98242V3.60107C8.71191 3.0957 8.43506 2.83203 7.95166 2.83203H3.55713C3.06494 2.83203 2.79688 3.0957 2.79688 3.60107V7.98242C2.79688 8.48779 3.06494 8.75146 3.55713 8.75146Z"
                                                                fill="black"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div className="_qrCode_2cae9_36" bis_skin_checked="1">
                                                    <div id="wallettocopy"
                                                         style={{display: 'none'}}>{userDetails ? (userDetails.dep_wallet_poligon) : ''}</div>
                                                    {userDetails ? (
                                                    <QRCodeCanvas value={userDetails.dep_wallet_poligon}
                                                                  style={{border: '2px solid #fff'}} alt="qr code url"
                                                                  size={120}/>
                                                    ) : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                            <div className="_textBlock_1s3p5_76" bis_skin_checked="1"><h5>Subscription options</h5>
                                <p>Subscribing for access to online exchanges of the EXPI Bot resource means getting the
                                    opportunity to use a service that is guaranteed to bring you daily profit.</p>
                                <p>EXPI Bot supports integration with fifty-six exchange services of the SdbS system in
                                    different countries, paid API protocol. The SdbS company provides services for
                                    simultaneous integration into the markets of its exchange services and the
                                    crypto-merchant for making exchanges automatically.</p><p>EXPI Bot uses pricing. the
                                    difference in the countries of exchange, various pairs of cryptocurrencies and
                                    transmits to you opportunities for making a profit. By paying for a subscription and
                                    gaining access, you work directly with the wallets of exchange services and receive
                                    the exchanged assets, with the profit generated through a profitable exchange, to
                                    your crypto wallets.</p><p>You can simultaneously make direct exchanges by manually
                                    selecting two exchange services with a favorable offer. This works according to the
                                    generally accepted principle, when you carry out an “Exchange of one type of crypto
                                    asset for another”, and the exchange takes place automatically on two exchange
                                    services with the return of the asset to your wallet with a profit (exchange
                                    delta).</p><p>And transit exchanges – this is an automatic mode of direct exchanges
                                    when you use a transit wallet and access to it 24/7. During a transit exchange,
                                    assets are automatically sent to the wallets of exchange services to carry out
                                    exchange operations and are returned with the exchange delta to the transit
                                    wallet.</p><h5>Subscription Features</h5><p>When you pay for a subscription, you
                                    receive a Private key, which opens access to exchanges and partnerships allows you
                                    to confirm the sending of crypto assets. You need to keep the Private Key in a safe
                                    place, so as not to lose it or transfer it to third parties. If you lose your
                                    Private Key, you need to restore it through the recovery form, using a valid Email
                                    address and paying for access again.</p><h5>Renew your subscription</h5><p>If you
                                    once paid for a subscription for a period of 7 days, then The private key that you
                                    received will be tied to the email address you specified during payment, and all
                                    subsequent access payments will be considered a renewal of the subscription. You
                                    need to click the “Extend subscription” function and pay for access using the
                                    previously specified Email address.</p></div>
                        </div>
                    </div>
                </div>
            </main>


    );
};

export default SubscriptionPage;
