import React from 'react';
import arbitratimg from '../img/arbitrat-strategy.svg';




const InstructionsPage = () => {


    return (
            <main>
                <div className="_container_4214v_15" bis_skin_checked="1">
                    <div className="_page_fni8s_1" bis_skin_checked="1">
                        <div className="_content_fni8s_9" bis_skin_checked="1">
                            <div bis_skin_checked="1"><h1>Instructions</h1><p>Thank you for using EXPI application
                                interfaces.</p><p>First, you need to thoroughly familiarize yourself with EXPI's
                                operating policy by studying the informational and static blocks of the service
                                provision order.</p><p>If you have decided to use our service and start earning, follow
                                this brief instruction to confidently navigate within our service.</p><p>Register:
                                confirm your email, and receive the Private Key for the free subscription on your
                                email.</p><p>After registration, you log in using the Private Key. The Private Key is
                                your additional security access option to the system, generated by the cryptographic
                                encryption algorithm protocol 'unique digital signature.' You must keep this data in a
                                secure place and not disclose it to third parties. Upon registration, you automatically
                                receive the Private Key for the free subscription. The free subscription provides access
                                to exchanges with amounts from 50 USDT to 1000 USDT, using 5 (five) crypto pairs. You
                                can enhance subscription features by switching to a paid tariff, more details in the
                                Subscription section.</p><p>Once you have the authorization data, you can proceed to
                                exchanges. You need to choose in which mode you will conduct exchanges: manual or
                                automatic, and top up the transit wallet in the selected section.</p><p>For direct
                                exchanges in manual mode, you need to select a crypto pair in the 'Available Pairs'
                                tab.</p><p>In the fields on the right and left, you see service providers for
                                cryptocurrency exchange; all work with EXPI Bot through the aggregate API protocol and
                                automatically execute your orders.</p><p>The asset prices you see are formed by each
                                exchange service in the country where the exchange service is located. Each exchange
                                service is linked to several major crypto exchanges, which play a primary role in
                                forming the price. The price is formed by the favorable market offer of the exchange and
                                includes all trading commissions (in some cases, the conversion fee), withdrawal fee,
                                and volatility indices to fix the price by the exchange provider. Therefore, you get a
                                fixed price and do not pay any other fees except the network fee when sending the asset
                                to your EXPI transit address.</p><p>You choose an acceptable price for the asset and fix
                                the necessary exchange service for selling and accordingly for buying the asset.</p>
                                <p>When the exchange provider and price are fixed, you enter the volume of the
                                    exchangeable asset.</p><p>The system will show you the profitability of this deal
                                    and your expected profit; after confirming the asset's sending, the rate will remain
                                    fixed.</p><p>Confirm the action with the 'Start Exchange' button, EXPI Bot will send
                                    the asset to the exchange service's wallet to conduct the exchange operation.</p>
                                <p>After sending the funds, all EXPI Bot clients need to do is wait for the transaction
                                    confirmation and processing of the exchange request by the exchange service
                                    provider.</p><p>You can track the exchange operation in the online exchange
                                    broadcast mode in the exchange provider's office by the operation statuses until the
                                    exchange asset is sent to your transit address. After completing the current direct
                                    exchange, you can proceed to the next one.</p><p>Automatic exchanges are a fully
                                    automated version of direct exchanges.</p><p>To allow the Bot to automatically send
                                    and receive the exchangeable asset, you also use the EXPI transit wallet for
                                    replenishment.</p><p>You send the asset to the transit wallet and enable the
                                    automatic exchange function.</p><p>The Bot automatically fixes the best price offer
                                    received from the exchange provider, conducting analysis across different pairs,
                                    sends assets for exchange, and indicates your transit wallet for receiving the
                                    exchangeable asset. You can enter and send funds from the transit address to your
                                    personal wallet at any time; in direct exchanges, you need to wait for the current
                                    exchange operation to complete if it is not finished. In Auto-exchanges, before
                                    withdrawing funds, you need to stop the auto-exchange and wait for the current
                                    exchange operation to complete. The interval between direct and automatic exchanges
                                    can be up to 10 minutes.</p></div>
                        </div>
                    </div>
                </div>
            </main>
    );
};

export default InstructionsPage;
