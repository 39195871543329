import React from 'react';

import step1 from '../startedScreens/step1.png';

import {Link} from "react-router-dom";


const GetStartedPage = () => {


    return (

            <main>
                <div className="_container_4214v_15" bis_skin_checked="1">
                    <div className="_page_8c2mu_1" bis_skin_checked="1"><h1 className="_padeTitle_8c2mu_323">How to
                        start?</h1>
                        <div className="_content_8c2mu_187" bis_skin_checked="1">
                            <ul className="_steps_8c2mu_14">
                                <li className="_step_8c2mu_14 _active_8c2mu_41"><span
                                    className="_stepSquare_8c2mu_45">1</span><span
                                    className="_stepDesc_8c2mu_62">Step 1</span></li>
                                <li className="_step_8c2mu_14"><span className="_stepSquare_8c2mu_45">2</span><span
                                    className="_stepDesc_8c2mu_62">Step 2</span></li>
                                <li className="_step_8c2mu_14"><span className="_stepSquare_8c2mu_45">3</span><span
                                    className="_stepDesc_8c2mu_62">Step 3</span></li>
                                <li className="_step_8c2mu_14"><span className="_stepSquare_8c2mu_45">4</span><span
                                    className="_stepDesc_8c2mu_62">Step 4</span></li>
                                <li className="_step_8c2mu_14"><span className="_stepSquare_8c2mu_45">5</span><span
                                    className="_stepDesc_8c2mu_62">Step 5</span></li>
                                <li className="_step_8c2mu_14"><span className="_stepSquare_8c2mu_45">6</span><span
                                    className="_stepDesc_8c2mu_62">Step 6</span></li>
                            </ul>
                            <div bis_skin_checked="1">
                                <div className="_block_8c2mu_75" bis_skin_checked="1"><h3
                                    className="_howToStart_8c2mu_201">How to start?</h3><h3
                                    className="_blockTitle_8c2mu_212">Welcome! To log in to the EXPI Bot system, you
                                    need Registration and a Private Key for a free subscription.</h3>
                                    <div className="_firstStepScreen_8c2mu_478" bis_skin_checked="1"><img
                                        loading="eager" src={step1} alt=""/></div>
                                    <div className="_firstStepScreenMobile_8c2mu_478" bis_skin_checked="1"><img
                                        loading="eager" src="/startedScreens/step1mobile.png" alt=""/></div>
                                    <ul className="_stepLi_8c2mu_236">
                                        <li>EXPI Bot is designed according to a risk-free arbitrage strategy — it is the
                                            immediate purchase and sale of an asset with profit from the price
                                            difference on your transit wallet.
                                        </li>
                                        <li>EXPI Bot operates with both a paid and free subscription. For authorization,
                                            you use your email and the Private Key of the subscription. You conduct
                                            exchanges using EXPI transit wallets, to which you have access to the wallet
                                            balances 24/7.
                                        </li>
                                    </ul>
                                    <div className="_btnWrapper_8c2mu_117" bis_skin_checked="1">
                                        <button className="_primary_1237m_41 _md_1237m_33 _button_1237m_1">Continue
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

    );
};

export default GetStartedPage;
