import React from 'react';

import logomain from '../img/landing/logoMain.png';
import windowImage from '../img/landing/windows.png';


const HomePageBlockPrivateKey = () => {


    return (
        <div>
        <div bis_skin_checked="1">
            <div className="_privatKeyWrapper_njvc2_220" bis_skin_checked="1">
                <div className="_privatKeyContent_njvc2_225" bis_skin_checked="1">
                    <div className="_imgPrivatKey_njvc2_269" bis_skin_checked="1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="112" height="125" viewBox="0 0 112 125"
                             fill="none">
                            <path
                                d="M39.5 86.3926C39.5 90.7481 40.502 94.0478 42.5997 96.2569C44.7022 98.4711 47.8196 99.5 51.849 99.5H99.151C103.18 99.5 106.298 98.4711 108.4 96.2569C110.498 94.0478 111.5 90.7481 111.5 86.3926V50.2578C111.5 46.3524 110.677 43.2962 108.959 41.1247C107.31 39.0407 104.89 37.8375 101.767 37.4387V27.3864C101.767 19.1591 98.9255 12.4298 94.2011 7.75667C89.4785 3.08528 82.906 0.5 75.5 0.5C68.117 0.5 61.5678 3.08564 56.8629 7.75734C52.1563 12.4306 49.3272 19.1597 49.3272 27.3864V37.4382C46.1828 37.8352 43.7397 39.026 42.0726 41.1046C40.3353 43.2707 39.5 46.3282 39.5 50.2578V86.3926ZM87.6483 37.2441H63.4457V27.0583C63.4457 23.0456 64.7589 19.8252 66.902 17.6108C69.045 15.3964 72.05 14.1542 75.5 14.1542C78.9504 14.1542 81.9799 15.3968 84.1468 17.6126C86.3132 19.8279 87.6483 23.0481 87.6483 27.0583V37.2441ZM94.3079 85.7989H56.7861C56.0031 85.7989 55.4857 85.5896 55.1576 85.2413C54.8243 84.8876 54.606 84.3057 54.606 83.3931V53.3042C54.606 52.3914 54.8243 51.8245 55.1533 51.4839C55.479 51.1465 55.9964 50.9452 56.7861 50.9452H94.3079C95.0974 50.9452 95.5843 51.1461 95.8846 51.4742C96.1934 51.8115 96.394 52.3792 96.394 53.3042V83.3931C96.394 84.3177 96.1936 84.9005 95.8805 85.2511C95.5776 85.5902 95.0904 85.7989 94.3079 85.7989Z"
                                stroke="#2EB15A"></path>
                            <path
                                d="M12.1828 125C4.06093 125 0 120.805 0 111.878V74.2683C0 66.2195 3.38411 62.0732 10.104 61.3902V50.4634C10.104 33.5854 21.5616 23 36.5 23C51.4868 23 62.9927 33.5854 62.9927 50.4634V61.3902C69.6642 62.0732 73 66.2683 73 74.2683V111.878C73 120.805 68.9391 125 60.8172 125H12.1828ZM23.5921 50.122V61.2439H49.5046V50.122C49.5046 41.5366 43.8483 36.1707 36.5 36.1707C29.1517 36.1707 23.5921 41.5366 23.5921 50.122ZM17.2589 111.78H55.8377C57.6265 111.78 58.4967 110.805 58.4967 108.756V77.439C58.4967 75.3902 57.6265 74.4634 55.8377 74.4634H17.2589C15.4702 74.4634 14.5033 75.3902 14.5033 77.439V108.756C14.5033 110.805 15.4702 111.78 17.2589 111.78Z"
                                fill="#2EB15A"></path>
                        </svg>
                    </div>
                    <div className="_privatKeyTitle_njvc2_246" bis_skin_checked="1">Private key – your reliable access
                        to the exchange system. Get the key and enter the EXPI system using the password principle.
                    </div>
                </div>
            </div>
        </div>
            <div bis_skin_checked="1">
                <div bis_skin_checked="1">
                    <div class="_statisticsWindowsBlockContent_njvc2_403" bis_skin_checked="1">
                        <div className="_leftBlockStatistics_njvc2_427" bis_skin_checked="1"><h2
                            className="_statisticsTitle_njvc2_328">Statistics for 24 Hours</h2><p
                            className="_numberOfExchanges_njvc2_348">48 exchange operations completed;</p>
                            <div className="_volumeOfExchangesBlock_njvc2_312" bis_skin_checked="1"><p
                                className="_volumeOfExchanges_njvc2_298">Exchanged Amount</p><p
                                className="_exchangeSumm_njvc2_361">20 000 USDT;</p></div>
                            <div className="_allProfitBlock_njvc2_323" bis_skin_checked="1"><p
                                className="_allProfit_njvc2_323">Profit Received</p><p
                                className="_totalProfit_njvc2_387">422,19 USDT.</p></div>
                        </div>
                        <div class="_rightBlockStatistics_njvc2_450" bis_skin_checked="1"><img src={windowImage}
                                                                                               alt=""/></div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default HomePageBlockPrivateKey;
