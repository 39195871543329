import React,{useState,useEffect} from 'react';


const importAll = (r) => {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('../flags', false, /\.(png|jpe?g|svg)$/));
const selectedCoin ="BTC"
const AsideLeft = ({ items, selectedCoin, activeItem, onItemClick }) => {


    return (
        <aside class="_asideLeft_j0ym2_18">
           <div class="_exchangesServicesTitle_j0ym2_58" bis_skin_checked="1">Exchange Services Purchase</div>
            <div class="_exchangesServicesBlock_j0ym2_70 scroll" bis_skin_checked="1">

                {items.map(item => (
                    <div className={`_card_d6fw5_1 ${activeItem?.id === item.id ? '_active_d6fw5_65' : ''}`} onClick={() => onItemClick(item)} key={item.id} bis_skin_checked="1">
                        <div className="_content_d6fw5_18" bis_skin_checked="1">
                            <div className="_serviceInfo_d6fw5_21" bis_skin_checked="1">
                                <div className="_flag_d6fw5_27" bis_skin_checked="1">
                                    <img src={images[item.short + ".svg"]} alt={"Flag img for " + item.alt} />
                                </div>
                                <div className="_name_d6fw5_34" bis_skin_checked="1">sdbs-{item.short}.com</div>
                            </div>
                            <div className="_price_d6fw5_40" bis_skin_checked="1">
                                <div className="_square_d6fw5_44" bis_skin_checked="1"></div>
                                <div className="_amount_d6fw5_51" bis_skin_checked="1">

                                    {item[selectedCoin]}
                                </div>
                            </div>
                        </div>
                        <div className="_lockIcon_d6fw5_57" bis_skin_checked="1">
                            <div className="_tooltip_1b29x_7" bis_skin_checked="1">
                                <button type="button" className="_link_1237m_75 _md_1237m_33 _button_1237m_1">
                                    <svg width="12" height="11" viewBox="0 0 12 11" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M2.05469 10.4551C1.23438 10.4551 0.824219 10.0352 0.824219 9.1416V5.37695C0.824219 4.49316 1.23438 4.07324 2.05469 4.07324H5.8291V2.94531C5.8291 1.25098 6.98633 0.196289 8.49512 0.196289C10.0088 0.196289 11.1709 1.25098 11.1709 2.94531V3.9707C11.1709 4.37598 10.8633 4.66895 10.4922 4.66895C10.1162 4.66895 9.80859 4.37109 9.80859 3.9707V2.91113C9.80859 2.05176 9.2373 1.51465 8.49512 1.51465C7.75293 1.51465 7.19141 2.05176 7.19141 2.91113V4.08789C7.86523 4.15625 8.19727 4.57617 8.19727 5.37695V9.1416C8.19727 10.0352 7.78711 10.4551 6.9668 10.4551H2.05469ZM2.56738 9.13184H6.46387C6.64453 9.13184 6.73242 9.03418 6.73242 8.8291V5.69434C6.73242 5.48926 6.64453 5.39648 6.46387 5.39648H2.56738C2.38672 5.39648 2.28906 5.48926 2.28906 5.69434V8.8291C2.28906 9.03418 2.38672 9.13184 2.56738 9.13184Z"
                                            fill="#2EB15A"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </aside>
    )
};

export default AsideLeft;
