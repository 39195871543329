import React from 'react';

import step1 from '../startedScreens/step1.png';


import {Link} from "react-router-dom";


const PasswordPage = () => {


    return (
        <main>
        <div class="_container_4214v_15" bis_skin_checked="1">
            <div class="_container_agdo9_1" bis_skin_checked="1">
                <div class="_pageTitle_agdo9_7" bis_skin_checked="1">Password</div>
                <div class="_content_agdo9_14" bis_skin_checked="1">
                    <div class="_note_agdo9_21" bis_skin_checked="1">Generate a complex password.</div>
                    <form>
                        <div class="_formInput_woglw_1" bis_skin_checked="1">
                            <div class="_md_1wje8_78" bis_skin_checked="1"><label class="_label_1wje8_1"><span
                                class="_labelText_1wje8_29">Your E-mail</span>
                                <div class="_inputContainer_1wje8_44" bis_skin_checked="1"><input
                                    class="_input_1wje8_7 " type="email" name="email"/></div>
                            </label></div>
                        </div>
                        <div class="_formInput_woglw_1" bis_skin_checked="1">
                            <div class="_md_1wje8_78" bis_skin_checked="1"><label class="_label_1wje8_1"><span
                                class="_labelText_1wje8_29">Subscription key</span>
                                <div class="_inputContainer_1wje8_44" bis_skin_checked="1"><input
                                    class="_input_1wje8_7 " type="text" name="subscriptionId" autocomplete="off"/></div>
                            </label></div>
                        </div>
                        <div class="_formInput_woglw_1" bis_skin_checked="1">
                            <div class="_md_1wje8_78" bis_skin_checked="1"><label class="_label_1wje8_1"><span
                                class="_labelText_1wje8_29">Create a password</span><span
                                class="_labelTooltip_1wje8_34"><div class="_tooltip_1b29x_7" bis_skin_checked="1"><button
                                type="button" class="_link_1237m_75 _md_1237m_33 _button_1237m_1"><svg width="10"
                                                                                                       height="10"
                                                                                                       viewBox="0 0 10 10"
                                                                                                       fill="none"
                                                                                                       xmlns="http://www.w3.org/2000/svg"><path
                                fill-rule="evenodd" clip-rule="evenodd"
                                d="M10 0H0V10H10V0ZM5.92788 8.5V4.43927H4.04808V8.5H5.92788ZM5.69712 2.18722C5.4984 2.06241 5.26442 2 4.99519 2C4.71955 2 4.48397 2.06241 4.28846 2.18722C4.09615 2.31204 4 2.55989 4 2.93077C4 3.29096 4.09615 3.53524 4.28846 3.66362C4.48397 3.78844 4.71955 3.85085 4.99519 3.85085C5.26442 3.85085 5.4984 3.78844 5.69712 3.66362C5.89904 3.53524 6 3.29096 6 2.93077C6 2.55989 5.89904 2.31204 5.69712 2.18722Z"
                                fill="#2EB15A"></path></svg></button></div></span>
                                <div class="_inputContainer_1wje8_44" bis_skin_checked="1"><input
                                    class="_input_1wje8_7 " type="password" name="password" autocomplete="off"/></div>
                                <button type="button" class="_eyeBtn_1wje8_100 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path
                                            d="M15 12c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-.199.02-.393.057-.581 1.474.541 2.927-.882 2.405-2.371.174-.03.354-.048.538-.048 1.657 0 3 1.344 3 3zm-2.985-7c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 12c-2.761 0-5-2.238-5-5 0-2.761 2.239-5 5-5 2.762 0 5 2.239 5 5 0 2.762-2.238 5-5 5z"></path>
                                    </svg>
                                </button>
                            </label></div>
                        </div>
                        <div class="_formInput_woglw_1" bis_skin_checked="1">
                            <div class="_md_1wje8_78" bis_skin_checked="1"><label class="_label_1wje8_1"><span
                                class="_labelText_1wje8_29">Repeat the password</span>
                                <div class="_inputContainer_1wje8_44" bis_skin_checked="1"><input
                                    class="_input_1wje8_7 " type="password" name="repeatPassword" autocomplete="off"/>
                                </div>
                                <button type="button" class="_eyeBtn_1wje8_100 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path
                                            d="M15 12c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-.199.02-.393.057-.581 1.474.541 2.927-.882 2.405-2.371.174-.03.354-.048.538-.048 1.657 0 3 1.344 3 3zm-2.985-7c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 12c-2.761 0-5-2.238-5-5 0-2.761 2.239-5 5-5 2.762 0 5 2.239 5 5 0 2.762-2.238 5-5 5z"></path>
                                    </svg>
                                </button>
                            </label></div>
                        </div>
                        <div class="_formButton_woglw_5" bis_skin_checked="1">
                            <button type="submit" class="_primary_1237m_41 _md_1237m_33 _button_1237m_1">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
)
};

export default PasswordPage;
